import React from "react";
import Title from "../../Elements/Title";
import TextField from "@mui/material/TextField";
import { childrenService } from "../../services/childrenService";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import MainButton from "../../Elements/MainButton";
import DangerButton from "../../Elements/DangerButton";

/**
 * This Component provides the Edit Functionality for the Doctors Notes.
 * @param props Props
 *
 * @return {JSX.Element}
 */
const DoctorsNotesEdit = (props) => {
    const { t } = useTranslation();

    const [value, setValue] = React.useState(props.currentText);
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [failAlertOpen, setFailAlertOpen] = React.useState(false);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    async function saveNote() {
        const status = await childrenService.saveDoctorsNote(
            props.child["url"],
            value
        );
        if (status === 200) {
            setSuccessAlertOpen(true);
            props.updateDoctorsNote(value);
        } else {
            setFailAlertOpen(true);
        }
    }

    return (
        <div style={{ padding: "30px", minHeight: "600px", overflow: "auto" }}>
            <Title>{t("doctors-notes.edit")}</Title>
            <TextField
                id="outlined-multiline-static"
                label={t("doctors-notes.note")}
                multiline
                rows={14}
                style={{ width: "100%", marginTop: "10px" }}
                onChange={handleChange}
                defaultValue={value}
            />
            <MainButton onClick={() => saveNote()}>{t("save")}</MainButton>
            <DangerButton onClick={() => props.closeModal()}>
                {t("close")}
            </DangerButton>
            <Box sx={{ width: "100%", marginTop: "10px" }}>
                <Collapse in={successAlertOpen}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                size="small"
                                onClick={() => setSuccessAlertOpen(false)}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {t("doctors-notes.successful-save")}
                    </Alert>
                </Collapse>
            </Box>

            <Box sx={{ width: "100%", marginTop: "10px" }}>
                <Collapse in={failAlertOpen}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                size="small"
                                onClick={() => setFailAlertOpen(false)}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {t("doctors-notes.erroneous-save")}
                    </Alert>
                </Collapse>
            </Box>
        </div>
    );
};

export default DoctorsNotesEdit;
