import TextField from "@mui/material/TextField";
import * as React from "react";
import { useEffect, useState } from "react";
import { authenticationService } from "../../services/AuthenticationService";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { localStorageService } from "../../services/LocalStorageService";

/**
 * This Component provides the Login logic. There is no registration logic implement yet.
 *
 * @return {JSX.Element}
 */
const PasswordChange = () => {
    let { t, i18n } = useTranslation();

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [failAlertOpen, setFailAlertOpen] = React.useState(false);
    const [failAlertText, setFailAlertText] = React.useState(
        t("change_pw.fail_submit")
    );

    const handleClick = (e) => {
        e.preventDefault();
        if (newPassword !== newPasswordRepeat) {
            setFailAlertOpen(true);
            setFailAlertText(t("change_pw.fail_repeat"));
        } else {
            authenticationService
                .changePassword(oldPassword, newPassword, newPasswordRepeat)
                .then((success) => {
                    if (success) {
                        setSuccessAlertOpen(true);
                        setFailAlertOpen(false);
                    } else {
                        setSuccessAlertOpen(false);
                        setFailAlertOpen(true);
                        setFailAlertText(t("change_pw.fail_submit"));
                    }
                });
        }
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                height: "65vh",
                marginTop: "150px",
            }}
        >
            <form onSubmit={handleClick}>
                <h1>{t("change_pw.title")}</h1>
                <Typography style={{ marginBottom: "10px" }}>
                    {t("change_pw.subtitle")}
                </Typography>
                <div style={{ marginBottom: "10px", marginRight: "150px" }}>
                    <TextField
                        fullWidth={true}
                        label={t("change_pw.old_password")}
                        type="password"
                        variant="standard"
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </div>
                <div style={{ marginBottom: "10px", marginRight: "150px" }}>
                    <TextField
                        fullWidth={true}
                        label={t("change_pw.new_password")}
                        type="password"
                        variant="standard"
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>
                <div style={{ marginBottom: "10px", marginRight: "150px" }}>
                    <TextField
                        fullWidth={true}
                        label={t("change_pw.new_password_repeat")}
                        type="password"
                        variant="standard"
                        onChange={(e) => setNewPasswordRepeat(e.target.value)}
                    />
                    <br />
                </div>
                <div>
                    <Box pt={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {t("change_pw.title")}
                        </Button>
                    </Box>
                </div>

                <Box sx={{ width: "50%" }}>
                    <Collapse in={successAlertOpen}>
                        <div style={{ marginTop: "30px" }}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        size="small"
                                        onClick={() =>
                                            setSuccessAlertOpen(false)
                                        }
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                {t("change_pw.success_submit")}
                            </Alert>
                        </div>
                    </Collapse>
                </Box>
                <Box sx={{ width: "50%" }}>
                    <Collapse in={failAlertOpen}>
                        <div style={{ marginTop: "30px" }}>
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        size="small"
                                        onClick={() => setFailAlertOpen(false)}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                {failAlertText}
                            </Alert>
                        </div>
                    </Collapse>
                </Box>
            </form>
        </div>
    );
};

export default PasswordChange;
