import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Stage,
    Layer,
    Rect,
    Text,
    Line,
    Circle,
    Image,
    Group,
} from "react-konva";
import useImage from "use-image";

/**
 * This Component provides the TwentyFourHoursProtocol for the Child Dashboard.
 *
 * @param props Props must provide the Child Information.
 *
 * @return {JSX.Element}
 */
const TargetRhythm = (props) => {
    const title = props?.title ? props?.title : "Ziel-Rhythmus";

    const { t } = useTranslation();

    const [width, setWidth] = useState(100);
    const [data, setData] = useState([]);

    const [allDatesToDisplay, setAllDatesToDisplay] = useState([]);
    const [allDatesDt, setAllDatesDt] = useState([]);
    const [allHoursOfDay, setAllHoursOfDay] = useState([]);
    const [sleepLogsSpans, setSleepLogsSpans] = useState([]);
    const [sleepLogsActionPoints, setSleepLogsActionPoints] = useState([]);
    const [mealLogsActionPoints, setMealLogsActionPoints] = useState([]);

    const demoRef = useRef();

    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            setWidth(event[0].contentBoxSize[0].inlineSize - 110);
        });

        if (demoRef) {
            resizeObserver.observe(demoRef.current);
        }
    }, [demoRef]);

    const widthPercentageDateDisplay = 0.12;
    const widthPercentageProtocolDisplay = 1 - widthPercentageDateDisplay;
    const rowHeight = 36;
    const topMargin = 20;
    const topMarginMain = 3;

    const zeroPad = (num, places) => String(num).padStart(places, "0");

    function calcXFromDate(date) {
        return (
            widthPercentageDateDisplay * width +
            ((date.getHours() * 60 + date.getMinutes()) / (60 * 24)) *
                widthPercentageProtocolDisplay *
                width
        );
    }

    function calcYFromDate(date) {
        return topMargin;
    }

    const sleepType = 0;
    const mealType = 1;

    useEffect(() => {
        if (props.data && data.length === 0) {
            setAllDatesToDisplay(["Rhythm"]);
            setAllDatesDt(["Rhythm"]);

            let startTime = new Date("2019-01-01");
            let endTime = new Date("2019-01-02");
            const allHoursOfDayToDisplay = [];
            while (startTime < endTime) {
                allHoursOfDayToDisplay.push(new Date(startTime));
                startTime.setMinutes(startTime.getMinutes() + 15);
            }

            setAllHoursOfDay(allHoursOfDayToDisplay);

            let sleepLogsSpansToDisplay = [];
            let sleepLogsActionPointsToDisplay = [];
            const sleepLogEntries = props.data.filter((x) => {
                return x["type"] === sleepType;
            });
            const mealLogs = props.data.filter((x) => {
                return x["type"] === mealType;
            });
            for (let i in sleepLogEntries) {
                let sleepLog = sleepLogEntries[i];
                let startTime = new Date(
                    "2019-01-01T" + sleepLog["start_time"]
                );
                startTime.setHours(startTime.getHours() - 6);
                let endTime = new Date("2019-01-01T" + sleepLog["end_time"]);
                endTime.setHours(endTime.getHours() - 6);

                let starTimeTime =
                    startTime.getHours() + startTime.getMinutes() / 60;
                let endTimeTime =
                    endTime.getHours() + endTime.getMinutes() / 60;

                if (starTimeTime > endTimeTime) {
                    const endTimeMidnight = new Date("2019-01-01T23:59:00");
                    const startTimeMidnight = new Date("2019-01-01T00:00:00");

                    sleepLogsActionPointsToDisplay.push(startTime);
                    sleepLogsActionPointsToDisplay.push(endTime);

                    sleepLogsSpansToDisplay.push([startTime, endTimeMidnight]);
                    sleepLogsSpansToDisplay.push([startTimeMidnight, endTime]);
                } else {
                    sleepLogsActionPointsToDisplay.push(startTime);
                    sleepLogsActionPointsToDisplay.push(endTime);

                    sleepLogsSpansToDisplay.push([startTime, endTime]);
                }
            }

            const mealLogsActionPointsToDisplay = [];
            for (let i in mealLogs) {
                let mealLog = mealLogs[i];
                let timestamp = new Date("2019-01-01T" + mealLog["start_time"]);
                timestamp.setHours(timestamp.getHours() - 6);
                mealLogsActionPointsToDisplay.push(timestamp);
            }
            setMealLogsActionPoints(mealLogsActionPointsToDisplay);
            setSleepLogsSpans(sleepLogsSpansToDisplay);
            setSleepLogsActionPoints(sleepLogsActionPointsToDisplay);
        }
    }, [props.data]);

    const [image] = useImage("/triangle.svg");

    return (
        <React.Fragment>
            <div ref={demoRef}>
                <Stage width={width + 15} height={topMargin}>
                    <Layer>
                        {allHoursOfDay.map((hourOfDay, i) => {
                            let fontSize = 12;
                            return hourOfDay.getMinutes() === 0 ? (
                                <Text
                                    x={calcXFromDate(hourOfDay) - 14}
                                    y={5}
                                    fontStyle={"normal"}
                                    fill="#3f51b5"
                                    text={`${zeroPad((hourOfDay.getHours() + 6) % 24, 2)}:00`}
                                    fontSize={fontSize}
                                />
                            ) : null;
                        })}
                    </Layer>
                </Stage>
                <Stage
                    width={width + 15}
                    height={rowHeight * allDatesToDisplay.length + 4}
                >
                    <Layer>
                        {allDatesToDisplay.map((dateToDisplay, i) => {
                            let fill =
                                i % 2 === 0 ? "white" : "rgb(194,226,247)";
                            return (
                                <Group>
                                    <Rect
                                        x={widthPercentageDateDisplay * width}
                                        y={topMarginMain + i * rowHeight}
                                        width={
                                            widthPercentageProtocolDisplay *
                                            width
                                        }
                                        height={rowHeight}
                                        key={0}
                                        fill={fill}
                                        stroke="rgb(176,209,221)"
                                    />
                                    <Rect
                                        x={2}
                                        y={topMarginMain + i * rowHeight}
                                        width={
                                            widthPercentageDateDisplay * width
                                        }
                                        height={rowHeight}
                                        fill={fill}
                                        key={1}
                                        stroke="rgb(176,209,221)"
                                    />
                                </Group>
                            );
                        })}

                        {allDatesDt.map((dateToDisplay, i) => {
                            const fontSize = 16;
                            const fontStyle = "normal";
                            const y = calcYFromDate(dateToDisplay);
                            return (
                                <Text
                                    x={12}
                                    y={y - 5}
                                    fontStyle={fontStyle}
                                    fill="black"
                                    text={`${title}`}
                                    fontSize={fontSize}
                                />
                            );
                        })}

                        {allHoursOfDay.map((hourOfDay, i) => {
                            let strokeWidth =
                                hourOfDay.getMinutes() === 0 ? 3 : 1;
                            return (
                                <Line
                                    points={[
                                        calcXFromDate(hourOfDay),
                                        topMarginMain,
                                        calcXFromDate(hourOfDay),
                                        allDatesToDisplay.length * rowHeight +
                                            topMarginMain,
                                    ]}
                                    stroke={"rgb(176,209,221)"}
                                    strokeWidth={strokeWidth}
                                    lineJoin={"round"}
                                />
                            );
                        })}

                        {sleepLogsActionPoints.map((sleepLogActionPoint, i) => {
                            return (
                                <Circle
                                    radius={4}
                                    fill={"#3f51b5"}
                                    x={calcXFromDate(sleepLogActionPoint)}
                                    y={calcYFromDate(sleepLogActionPoint)}
                                />
                            );
                        })}

                        {sleepLogsSpans.map((sleepLogsSpan, i) => {
                            return (
                                <Line
                                    points={[
                                        calcXFromDate(sleepLogsSpan[0]),
                                        calcYFromDate(sleepLogsSpan[0]),
                                        calcXFromDate(sleepLogsSpan[1]),
                                        calcYFromDate(sleepLogsSpan[1]),
                                    ]}
                                    stroke={"#3f51b5"}
                                    strokeWidth={3}
                                    lineJoin={"round"}
                                />
                            );
                        })}

                        {mealLogsActionPoints.map((mealLogsActionPoint, i) => {
                            return (
                                <Image
                                    width={20}
                                    height={20}
                                    x={calcXFromDate(mealLogsActionPoint) - 10}
                                    y={calcYFromDate(mealLogsActionPoint) - 10}
                                    image={image}
                                />
                            );
                        })}
                    </Layer>
                </Stage>
            </div>
        </React.Fragment>
    );
};

export default TargetRhythm;
