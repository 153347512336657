import React, { useEffect, useRef, useState } from "react";
import Title from "../../Elements/Title";
import { useTranslation } from "react-i18next";
import { calcAgeInMonths } from "../Utils/Util";
import Button from "@mui/material/Button";
import ModalElement from "../../Elements/ModalElement";
import DoctorsNotesEdit from "./DoctorsNotesEdit";
import TextCollapse from "../../Elements/TextCollapse";
import GeneralInformationItem from "./GeneralInformationItem";

/**
 * This Component provides the General Child Information for the Child Dashboard.
 *
 * @param props Props must provide the Child Information.
 *
 * @return {JSX.Element}
 */
const GeneralInformation = (props) => {
    const { t } = useTranslation();
    const editDoctorsNotesRef = useRef(null);

    const [ageDisplay] = useState(() => {
        const ageInMonths = calcAgeInMonths(props.data.birthday);
        const years = Math.floor(ageInMonths / 12);
        const months = ageInMonths - 12 * years;
        return years !== 0
            ? `${years} ${t("infos.age.years")}, ${months} ${t("infos.age.months")}`
            : `${months} ${t("infos.age.months")}`;
    });

    const [earlyBirthAge] = useState(() => {
        if (props.data.birthday_early_birth === null) return null;
        else {
            return (
                new Date(props.data.birthday_early_birth).getDate() -
                new Date(props.data.birthday).getDate()
            );
        }
    });

    const [careTakerConcat] = useState(() => {
        let returnString = "";
        for (let i = 0; i < props.data["caretakers_emails"].length; ++i) {
            returnString +=
                props.data["caretakers_names"][i] +
                " (" +
                props.data["caretakers_emails"][i] +
                ")";
            if (i !== props.data["caretakers_emails"].length - 1) {
                returnString += ", ";
            }
        }
        return returnString;
    });

    const [bedTimeSum, setBedTimeSum] = useState(0);
    const [minutesUntilSleepSum, setMinutesUntilSleepSum] = useState(0);
    const [sleepTimeSum, setSleepTimeSum] = useState(0);

    function diff_minutes(dt2, dt1) {
        let diff = (dt2.getTime() - dt1.getTime()) / (1000 * 60);
        return Math.round(Math.abs(diff));
    }

    function minutesToHoursAndMinutesString(minutes) {
        return `${parseInt(minutes / 60)} ${t("hours")}, ${parseInt(minutes % 60)} ${t("minutes")}`;
    }

    function setTimeToSix(date) {
        date.setHours(6);
        date.setMinutes(0);
        date.setSeconds(0);
    }

    function getMaxDate(dates) {
        if (dates.length === 0) {
            return null;
        }
        let maxDate = new Date(dates[0]["end_time"]);
        for (let i = 1; i < dates.length; i++) {
            let indexDate = new Date(dates[i]["end_time"]);
            if (indexDate > maxDate) {
                maxDate = indexDate;
            }
        }
        return maxDate;
    }

    function getMinDate(dates) {
        if (dates.length === 0) {
            return null;
        }
        let minDate = new Date(dates[0]["begin_time"]);
        for (let i = 1; i < dates.length; i++) {
            let indexDate = new Date(dates[i]["begin_time"]);
            if (indexDate < minDate) {
                minDate = indexDate;
            }
        }
        return minDate;
    }

    useEffect(() => {
        if (props.needForSleep["need_for_sleep"]) {
            let needForSleep = props.needForSleep["need_for_sleep"];
            let sleepTimeMinutesSum =
                needForSleep.hours * 60 + needForSleep.minutes;
            setSleepTimeSum(sleepTimeMinutesSum);
            setMinutesUntilSleepSum(needForSleep.bedtime);
        }
    }, [props]);

    const [showMoreInformation, setShowMoreInformation] = useState(false);
    const [doctorsNotes, setDoctorsNotes] = useState(
        props.data.doctors_notes
            ? props.data.doctors_notes
            : t("doctors-notes.no-note")
    );

    const dateDisplayProps = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    };

    return (
        <div>
            <div>
                <Title>{t("infos.title")}</Title>
                <GeneralInformationItem
                    label={t("infos.id")}
                    value={"#" + props.data.id}
                />
                <GeneralInformationItem
                    label={t("infos.name")}
                    value={props.data.name}
                />
                <GeneralInformationItem
                    label={t("infos.birthday")}
                    value={new Date(props.data.birthday).toLocaleDateString(
                        "de-DE",
                        dateDisplayProps
                    )}
                />
                <GeneralInformationItem
                    label={t("infos.age")}
                    value={ageDisplay}
                />
                {earlyBirthAge ? (
                    <GeneralInformationItem
                        label={t("infos.early-birth")}
                        value={earlyBirthAge + " " + t("infos.age.days")}
                    />
                ) : (
                    ""
                )}
                <GeneralInformationItem
                    label={t("infos.sex")}
                    value={props.data.sex}
                />
                <GeneralInformationItem
                    label={t("infos.childPhase")}
                    value={props.childPhase.phase ?? t("loading") + "..."}
                />
                {showMoreInformation ? (
                    <span>
                        <GeneralInformationItem
                            label={"Schlafbedarf "}
                            value={minutesToHoursAndMinutesString(sleepTimeSum)}
                            infobox={"Berechnet von den ersten 14 Tagen"}
                        />
                        <GeneralInformationItem
                            label={"Bettzeit"}
                            value={`+${parseInt(minutesUntilSleepSum)} ${t("minutes")}`}
                            infobox={"Berechnet von den ersten 14 Tagen"}
                        />
                    </span>
                ) : null}
            </div>
            {showMoreInformation ? (
                <div style={{ marginTop: "30px" }}>
                    <Title>{t("infos.more-information")}</Title>
                    <GeneralInformationItem
                        label={t("infos.guardians")}
                        value={careTakerConcat}
                    />
                </div>
            ) : null}

            {showMoreInformation ? (
                <div style={{ marginTop: "20px" }}>
                    <div
                        style={{
                            color: "#3f51b5",
                            fontWeight: 600,
                            fontSize: "0.75em",
                        }}
                    >
                        {t("infos.doctors_notes")}
                    </div>
                    <TextCollapse
                        text={doctorsNotes}
                        maxLength={130}
                        showMoreFunction={() =>
                            editDoctorsNotesRef.current.handleOpen(true)
                        }
                    />
                    <ModalElement ref={editDoctorsNotesRef}>
                        <div style={{ borderRadius: "4px" }}>
                            <DoctorsNotesEdit
                                currentText={doctorsNotes}
                                child={props.data}
                                updateDoctorsNote={setDoctorsNotes}
                                closeModal={() =>
                                    editDoctorsNotesRef.current.handleOpen(
                                        false
                                    )
                                }
                            ></DoctorsNotesEdit>
                        </div>
                    </ModalElement>
                </div>
            ) : null}

            <Button
                sx={{
                    color: "#3f51b5",
                    marginTop: "20px",
                    border: "1px solid rgba(63, 81, 181, 1)",
                    "&:hover": {
                        backgroundColor: "rgba(63, 81, 181, 0.85)",
                        color: "white",
                    },
                }}
                onClick={() => setShowMoreInformation(!showMoreInformation)}
            >
                {showMoreInformation
                    ? t("infos.less-infos")
                    : t("infos.more-infos")}
            </Button>
        </div>
    );
};

export default GeneralInformation;
