import Button from "@mui/material/Button";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const DangerButton = styled(Button)(({ theme }) => ({
    color: "white",
    marginTop: "20px",
    marginLeft: "5px",
    backgroundColor: "rgba(181, 81, 93, 0.9)",
    border: "1px solid rgba(181, 81, 93, 1)",
    "&:hover": {
        backgroundColor: "rgba(181, 81, 93, 0.7)",
        color: "white",
    },
}));

export default DangerButton;
