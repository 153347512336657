import { Dialog, IconButton, Slide } from "@mui/material";
import React, { useState } from "react";
import ListIcon from "@mui/icons-material/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@mui/material/Typography";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@mui/material/Paper";
import { childrenService } from "../../services/childrenService";
import ClearIcon from "@material-ui/icons/Clear";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckIcon from "@material-ui/icons/Check";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function InterventionHistory(props) {
    const [open, setOpen] = React.useState(false);
    const [interventionHistory, setInterventionHistory] = useState([]);

    const getInterventionHistory = () => {
        if (props.intervention?.original_url) {
            childrenService
                .getInterventionHistory(props.intervention.original_url)
                .then((r) => {
                    setInterventionHistory(r);
                    setOpen(true);
                })
                .catch((err) => console.log(err));
        } else {
            console.log(props.intervention);
            setInterventionHistory([]);
        }
    };

    const handleClickOpen = () => {
        getInterventionHistory();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case 0:
                return <></>;
            case 1:
                return (
                    <CheckIcon
                        style={{
                            background: props.statusColorMap[1],
                            color: "white",
                            borderRadius: "20px",
                            padding: "3px",
                        }}
                    />
                );
            case 2:
                return (
                    <RadioButtonUncheckedIcon
                        style={{
                            background: props.statusColorMap[2],
                            color: "white",
                            borderRadius: "20px",
                            padding: "3px",
                        }}
                    />
                );
            case 3:
                return (
                    <ClearIcon
                        style={{
                            background: props.statusColorMap[3],
                            color: "white",
                            borderRadius: "20px",
                            padding: "3px",
                        }}
                    />
                );
            default:
                return <></>;
        }
    };
    return (
        <React.Fragment>
            <ListIcon onClick={handleClickOpen}></ListIcon>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            sx={{ ml: 2, flex: 1 }}
                            variant="h6"
                            component="div"
                            style={{
                                paddingLeft: "0.5rem",
                            }}
                        >
                            Intervention History for Intervention #
                            {props.intervention.original_url.split("/").pop()}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <TableContainer
                    component={Paper}
                    style={{
                        marginTop: "8vh",
                    }}
                >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">status_date</TableCell>
                                <TableCell align="right">status</TableCell>
                                <TableCell align="right">
                                    user_feedback
                                </TableCell>
                                <TableCell align="right">
                                    user_negative_feedback_detail
                                </TableCell>
                                <TableCell align="right">
                                    updated_from
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {interventionHistory.map((row) => (
                                <TableRow
                                    key={row?.url}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell align="right">
                                        {new Date(
                                            row?.status_date
                                        ).toDateString()}
                                        ,{" "}
                                        {new Date(
                                            row?.status_date
                                        ).toLocaleTimeString()}
                                    </TableCell>
                                    <TableCell align="right">
                                        {getStatusIcon(row?.status)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row?.status}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row?.user_negative_feedback_detail}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row?.updated_from}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Dialog>
        </React.Fragment>
    );
}
