import React from "react";
import "./index.css";
import App from "./App";
import "./i18n";
import { createRoot } from "react-dom/client";
import "./components/Utils/AxiosInterceptors";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
    // <React.StrictMode>
    <React.Fragment>
        <App />
    </React.Fragment>
);

// serviceWorker.unregister();
