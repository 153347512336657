import React, { useEffect, useRef, useState } from "react";
import { QuestionView } from "./QuestionView";
import Title from "../../Elements/Title";
import ModalElement from "../../Elements/ModalElement";
import Button from "@mui/material/Button";

export const QuestionnaireView = (props) => {
    const [questionnaires, setQuestionnaires] = useState();
    const ref = useRef();

    useEffect(() => {
        if (props.questionnaires) setQuestionnaires(props.questionnaires);
    }, [props]);

    return (
        <div>
            <Title
                sx={{
                    display: "inline",
                    width: "40%",
                }}
            >
                Fragebögen
            </Title>

            {questionnaires?.length > 0 ? (
                <Button
                    onClick={() => {
                        ref.current.handleOpen(true);
                    }}
                >
                    Zeige Fragebögen
                </Button>
            ) : (
                <div>Keine Fragebögen vorhanden</div>
            )}

            <ModalElement ref={ref}>
                <div
                    style={{
                        padding: "30px",
                        minHeight: "600px",
                        overflow: "auto",
                    }}
                >
                    {questionnaires?.map((questionnaire) => {
                        return (
                            <div>
                                <span>
                                    <Title
                                        sx={{
                                            display: "inline",
                                            width: "40%",
                                        }}
                                    >
                                        Fragebogen{" "}
                                        {new Date(
                                            questionnaire.create_time
                                        ).toLocaleDateString("en-GB")}{" "}
                                        <span style={{ color: "grey" }}>
                                            {questionnaire["filled_out"]
                                                ? "[Ausgefüllt]"
                                                : "[Noch nicht ausgefüllt]"}
                                        </span>
                                    </Title>
                                    {questionnaire.questions.map((question) => {
                                        return (
                                            <div>
                                                <QuestionView
                                                    questionUrl={question}
                                                ></QuestionView>
                                                <div
                                                    style={{
                                                        backgroundColor: "grey",
                                                        height: "1px",
                                                        width: "100%",
                                                    }}
                                                ></div>
                                            </div>
                                        );
                                    })}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </ModalElement>
        </div>
    );
};
