import Title from "../../Elements/Title";
import React, { useEffect, useRef, useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextCollapse from "../../Elements/TextCollapse";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import IconButtonElement from "../../Elements/IconButtonElement";
import ModalElement from "../../Elements/ModalElement";
import MessageToGuardiansCreate from "./MessageToGuardiansCreate";
import { MenuItem } from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import ScrollbarElement from "../../Elements/ScrollbarElement";
import { childrenService } from "../../services/childrenService";

const MessageToGuardiansDisplay = (props) => {
    const { t } = useTranslation();
    const addMessageModalRef = useRef(null);

    useEffect(() => {
        setParentMessages(props.messagesToParents);
    }, [props]);

    async function archiveMessage(message) {
        message.status = 1;
        await childrenService.archiveMessage(message);
        const messagesToParentsLogs =
            await childrenService.getAllMessagesToParent(
                props.child["messages_to_parents_set"]
            );
        props.setMessagesToParents(messagesToParentsLogs);
    }

    const [parentMessages, setParentMessages] = useState([]);
    const dateFormat = { year: "numeric", month: "2-digit", day: "2-digit" };

    return (
        <div>
            <Title sx={{ display: "inline" }}>Nachrichten an Eltern</Title>
            <IconButtonElement
                aria-label="edit"
                size="small"
                onClick={() => addMessageModalRef.current.handleOpen(true)}
            >
                <AddIcon
                    style={{ width: ".7em", height: ".7em", color: "inherit" }}
                />
            </IconButtonElement>
            <ModalElement ref={addMessageModalRef}>
                <div style={{ borderRadius: "4px" }}>
                    <MessageToGuardiansCreate
                        child={props.child}
                        setMessagesToParents={props.setMessagesToParents}
                        closeModal={() =>
                            addMessageModalRef.current.handleOpen(false)
                        }
                    />
                </div>
            </ModalElement>

            <ScrollbarElement
                style={{
                    maxHeight: "350px",
                    width: "100%",
                    paddingRight: "10px",
                }}
            >
                {parentMessages && parentMessages.length > 0 ? (
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableBody>
                                {parentMessages
                                    .sort((x1, x2) => x1.status - x2.status)
                                    .map((parentMessage, index) => {
                                        return (
                                            <TableRow key={index} hover={true}>
                                                <TableCell>
                                                    <h4
                                                        style={{
                                                            marginBottom: "1px",
                                                            marginRight: "5px",
                                                            marginTop: "5px",
                                                            display:
                                                                "inline-block",
                                                            color:
                                                                parentMessage.status ===
                                                                0
                                                                    ? "inherit"
                                                                    : "gray",
                                                        }}
                                                    >
                                                        {parentMessage.title}
                                                    </h4>
                                                    <span
                                                        style={{
                                                            color: "grey",
                                                        }}
                                                    >
                                                        [
                                                        {new Date(
                                                            parentMessage.create_time
                                                        ).toLocaleDateString(
                                                            "de-DE",
                                                            dateFormat
                                                        )}
                                                        ]
                                                    </span>
                                                    <TextCollapse
                                                        text={
                                                            parentMessage.message_content
                                                        }
                                                        maxLength={140}
                                                    >
                                                        {" "}
                                                    </TextCollapse>
                                                </TableCell>
                                                <TableCell
                                                    style={{ width: "50px" }}
                                                >
                                                    <MenuItem
                                                        onClick={() =>
                                                            archiveMessage(
                                                                parentMessage
                                                            )
                                                        }
                                                        disabled={
                                                            parentMessage.status ===
                                                            1
                                                        }
                                                        style={{
                                                            display:
                                                                "inline-block",
                                                            marginTop: "10px",
                                                            padding: "5px",
                                                        }}
                                                    >
                                                        <ArchiveIcon
                                                            style={{
                                                                color: "#3f51b5",
                                                            }}
                                                        />
                                                    </MenuItem>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography>Keine Nachrichten gefunden</Typography>
                )}
            </ScrollbarElement>
        </div>
    );
};

export default MessageToGuardiansDisplay;
