import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "../../Elements/Title";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { MenuItem, Tooltip } from "@material-ui/core";
import { childrenService } from "../../services/childrenService";
import Typography from "@material-ui/core/Typography";
import TextCollapse from "../../Elements/TextCollapse";
import ScrollbarElement from "../../Elements/ScrollbarElement";
import Divider from "@material-ui/core/Divider";
import {
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
    useTheme,
} from "@mui/material";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import InterventionMenu from "./InterventionMenu";

/**
 * This Component shows the Algorithm Recommendation.
 *
 * @param props Props
 * @return {JSX.Element}
 */
const AlgoRecommendation = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [displayedInterventions, setDisplayedInterventions] = useState([]);
    const [allInterventions, setAllInterventions] = useState([]);
    const [recommendedInterventions, setRecommendedInterventions] = useState(
        []
    );

    const [selectedCategories, setSelectedCategories] = useState([0, 1]);
    const [selectedSubCategories, setSelectedSubCategories] = useState([
        0, 1, 2,
    ]);
    const [tableCellHeight, setTableCellHeight] = useState(10);

    const [selectedIntervention, setSelectedIntervention] = useState("");

    const categories = [
        [0, "Sleep"],
        [1, "Eat"],
    ];

    const subCategories = [
        [0, "Quality"],
        [1, "Quantity"],
        [2, "Regularity"],
    ];

    const statusToDatePreText = {
        0: "Vorgeschlagen am",
        1: "Akzeptiert am",
        2: "Als zu unklar deklariert am",
        3: "Abgelehnt am",
        4: "Abgelaufen am",
    };

    const expireDatePreText = {
        0: "Vorschlag verfallt am",
        1: "Verfallt am",
        2: "Verfallt am",
        3: "Verfallt am",
        4: "Abgelaufen am",
    };

    const statusColorMap = {
        0: "rgba(0, 120, 180, 0.7)",
        1: "rgba(0, 180, 15, 0.7)",
        2: "rgba(255, 165, 0, 0.7)",
        3: "rgba(232, 36, 12, 0.7)",
    };

    const demoRef = useRef();

    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            setTableCellHeight(event[0].contentBoxSize[0]["blockSize"]);
        });

        if (demoRef.current) {
            resizeObserver.observe(demoRef.current);
        }
    }, [demoRef]);

    useEffect(() => {
        if (props.recommendedInterventions)
            setRecommendedInterventions(props.recommendedInterventions);
        if (props.allInterventions) setAllInterventions(props.allInterventions);
        props.allInterventions.forEach((intervention) => {
            let index = props.activeInterventions.findIndex(
                (x) => x["intervention_definition_url"] === intervention["url"]
            );
            if (index !== -1) {
                intervention["status"] =
                    props.activeInterventions[index]["status"];
                intervention["status_date"] =
                    props.activeInterventions[index]["status_date"];
                intervention["update_url"] =
                    props.activeInterventions[index]["url"];
            } else {
                intervention["status"] = null;
                intervention["update_url"] = props.child["interventions_set"];
            }
            intervention["active_index"] = index;
            intervention["original_url"] =
                index >= 0 ? intervention["update_url"] : "";
        });

        let sortedInterventions = allInterventions.sort((x1, x2) => {
            let s1 = x1.status;
            let s2 = x2.status;
            if (x2.status === null) s2 = 999;
            if (x1.status === null) s1 = 999;
            return s1 - s2;
        });
        setDisplayedInterventions(sortedInterventions);
    }, [props, props.allInterventions]);

    const classifyIntervention = async (interventionUrl, newStatus, index) => {
        if (displayedInterventions[index]["status"] === newStatus) return;

        if (displayedInterventions[index].status == null) {
            await childrenService.classifyIntervention(
                interventionUrl,
                newStatus,
                displayedInterventions[index],
                props.child.url
            );
        } else {
            await childrenService.reclassifyIntervention(
                interventionUrl,
                newStatus
            );
        }

        const interventionDefinitions = await childrenService.getInterventions(
            props.child["interventions_set"],
            props.child["intervention_definitions_for_child"]
        );
        props.setActiveInterventions(
            interventionDefinitions["activeInterventions"]
        );

        // since we do not use any global state, we have to trigger a rerender manually
        setSelectedIntervention(interventionUrl);
    };

    const handleChange = (event, selectType) => {
        const {
            target: { value },
        } = event;
        switch (selectType) {
            case "category":
                let filterCategoryResult =
                    typeof value === "string" ? value.split(",") : value;
                setDisplayedInterventions(
                    allInterventions
                        .filter((x) =>
                            filterCategoryResult.includes(x["category"])
                        )
                        .filter((x) =>
                            selectedSubCategories.includes(x["sub_category"])
                        )
                );
                setSelectedCategories(filterCategoryResult);

                break;
            case "subcategory":
                let filterSubCategoryResult =
                    typeof value === "string" ? value.split(",") : value;
                setDisplayedInterventions(
                    allInterventions
                        .filter((x) =>
                            selectedCategories.includes(x["category"])
                        )
                        .filter((x) =>
                            filterSubCategoryResult.includes(x["sub_category"])
                        )
                );
                setSelectedSubCategories(filterSubCategoryResult);
                break;
            default:
                console.log("Select Type not Found...");
        }
    };

    const _renderStatusString = (intervention) => {
        let statusDate = intervention["status_date"];
        if (!statusDate) {
            return "";
        }
        let dateString = new Date(statusDate).toLocaleDateString();
        let preDateText = statusToDatePreText[intervention["status"]];
        return preDateText + " " + dateString;
    };

    const _renderExpireString = (intervention) => {
        let statusDate = intervention["status_date"];
        if (!statusDate) {
            return "";
        }
        let date = new Date(statusDate);
        date.setDate(
            date.getDate() + parseInt(intervention["active_time_in_days"])
        );
        let dateString = date.toLocaleDateString();
        let preDateText = expireDatePreText[intervention["status"]];
        return preDateText + " " + dateString;
    };

    return (
        <div>
            <span>
                <Title sx={{ display: "inline", width: "40%" }}>
                    Alle Interventionen
                </Title>
            </span>

            <div style={{ paddingTop: "2px", float: "right" }}>
                <FormControl
                    sx={{ m: 0, width: 250, marginRight: "20px" }}
                    size="small"
                >
                    <InputLabel>Categories</InputLabel>
                    <Select
                        multiple
                        value={selectedCategories}
                        onChange={(e) => handleChange(e, "category")}
                        input={<OutlinedInput label="Categories" />}
                    >
                        {categories.map((category) => (
                            <MenuItem key={category[0]} value={category[0]}>
                                {category[1]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 0, width: 250 }} size="small">
                    <InputLabel>Subcategories</InputLabel>
                    <Select
                        multiple
                        value={selectedSubCategories}
                        onChange={(e) => handleChange(e, "subcategory")}
                        input={<OutlinedInput label="Subcategories" />}
                    >
                        {subCategories.map((category) => (
                            <MenuItem key={category[0]} value={category[0]}>
                                {category[1]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <span style={{ clear: "both" }}></span>
            {props.childPhase.phase && props.childPhase.phase <= 1 ? (
                <div style={{ color: "deeppink" }}>
                    {" "}
                    {t("child.stillPhaseOne")}{" "}
                </div>
            ) : (
                <div></div>
            )}
            {props.childPhase.phase && props.childPhase.phase >= 2 ? (
                <div>
                    <ScrollbarElement
                        style={{
                            maxHeight: "500px",
                            width: "100%",
                            paddingRight: "10px",
                        }}
                    >
                        <TableContainer ref={demoRef}>
                            {displayedInterventions &&
                            displayedInterventions.length > 0 ? (
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {displayedInterventions.map(
                                            (intervention, index) => {
                                                return (
                                                    <TableRow
                                                        key={intervention.url}
                                                        hover={true}
                                                    >
                                                        <TableCell
                                                            style={{
                                                                width: "10px",
                                                                padding: "8px",
                                                                backgroundColor: `${statusColorMap[intervention["status"]]}`,
                                                            }}
                                                        ></TableCell>
                                                        <TableCell>
                                                            <div>
                                                                {_renderStatusString(
                                                                    intervention
                                                                )}
                                                            </div>
                                                            <div>
                                                                {_renderExpireString(
                                                                    intervention
                                                                )}
                                                            </div>
                                                            <div>
                                                                {intervention.status ===
                                                                0 ? (
                                                                    <Tooltip
                                                                        title="Vorschlag des Regelsystems"
                                                                        placement="top"
                                                                    >
                                                                        <SettingsSuggestOutlinedIcon
                                                                            sx={{
                                                                                fontSize: 20,
                                                                                color: "#3f51b5",
                                                                                marginRight:
                                                                                    "5px",
                                                                                position:
                                                                                    "relative",
                                                                                bottom: "-3px",
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                ) : null}
                                                                <h4
                                                                    style={{
                                                                        marginBottom:
                                                                            "1px",
                                                                        display:
                                                                            "inline",
                                                                        marginRight:
                                                                            "10px",
                                                                        marginTop:
                                                                            "5px",
                                                                    }}
                                                                >
                                                                    {
                                                                        intervention.title
                                                                    }
                                                                </h4>
                                                                <span
                                                                    style={{
                                                                        color: "grey",
                                                                    }}
                                                                >
                                                                    [
                                                                    {
                                                                        categories[
                                                                            intervention
                                                                                .category
                                                                        ][1]
                                                                    }{" "}
                                                                    -{" "}
                                                                    {
                                                                        subCategories[
                                                                            intervention
                                                                                .sub_category
                                                                        ][1]
                                                                    }
                                                                    ] [Phase{" "}
                                                                    {intervention?.phase
                                                                        ? intervention?.phase
                                                                        : "N/A"}
                                                                    ]
                                                                </span>
                                                                <TextCollapse
                                                                    text={
                                                                        intervention.text
                                                                    }
                                                                    maxLength={
                                                                        110
                                                                    }
                                                                >
                                                                    {" "}
                                                                </TextCollapse>
                                                            </div>
                                                        </TableCell>

                                                        <TableCell
                                                            style={{
                                                                width: "250px",
                                                            }}
                                                        >
                                                            <InterventionMenu
                                                                onClick={() =>
                                                                    classifyIntervention(
                                                                        intervention[
                                                                            "update_url"
                                                                        ],
                                                                        1,
                                                                        index
                                                                    )
                                                                }
                                                                setSelectedIntervention={
                                                                    setSelectedIntervention
                                                                }
                                                                selectedIntervention={
                                                                    selectedIntervention
                                                                }
                                                                intervention={
                                                                    intervention
                                                                }
                                                                index={index}
                                                                statusColorMap={
                                                                    statusColorMap
                                                                }
                                                                classifyIntervention={
                                                                    classifyIntervention
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            ) : (
                                <span>
                                    <Typography
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        {t("algo.noInterventions")}
                                    </Typography>
                                </span>
                            )}
                        </TableContainer>
                    </ScrollbarElement>
                </div>
            ) : (
                <div></div>
            )}
            <Divider style={{ marginRight: "22px" }} />
        </div>
    );
};

export default AlgoRecommendation;
