import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import AlgoRecommendation from "./AlgoRecommendation";
import { useTranslation } from "react-i18next";
import { childrenService } from "../../services/childrenService";
import PaperElement from "../../Elements/PaperElement";
import ModalElement from "../../Elements/ModalElement";
import Title from "../../Elements/Title";
import Button from "@mui/material/Button";
import ProcessedInterventions from "./ProcessedInterventions";
import GeneralInformation from "./GeneralInformation";
import TwentyFourHoursProtocol from "./24HoursProtocol";
import MessageToGuardiansDisplay from "./MessageToGuardiansDisplay";
import { QuestionnaireView } from "./QuestionnaireView";
import { WellnessView } from "./WellnessView";
import { userService } from "../../services/userService";

/**
 * This Component Provides the Dashboard for each Child. Its shows the Log-Charts, Rhythm-Charts, Child-Information, Algorithm-Scoring and Recommendations.
 *
 * @return {JSX.Element}
 */
const Dashboard = () => {
    let { id } = useParams();
    const { t } = useTranslation();

    const [child, setChild] = useState("");
    const [childPhase, setChildPhase] = useState([]);
    const [childNeedForSleep, setChildNeedForSleep] = useState([]);
    const [childWellness, setChildWellness] = useState([]);
    const [userWellness, setUserWellness] = useState([]);
    const [activeInterventions, setActiveInterventions] = useState([]);
    const [allInterventions, setAllInterventions] = useState([]);
    const [questionnaires, setAllQuestionnaires] = useState([]);
    const [targetRhythmLogs, setTargetRhythmLogs] = useState([]);
    const [rhythmPropositionLogs, setRhythmPropositionLogs] = useState([]);
    const [rhythmLogs, setRhythmLogs] = useState([]);
    const [rhythmDate, setRhythmDate] = useState("");
    const [allActivityLogs, setAllActivityLogs] = useState(null);
    const [messagesToParents, setMessagesToParents] = useState(null);
    const [validDays, setValidDays] = useState([]);

    const acceptedInterventionModalRef = useRef(null);
    const vagueInterventionModalRef = useRef(null);
    const deniedInterventionModalRef = useRef(null);

    const dateFormat = { year: "numeric", month: "2-digit", day: "2-digit" };

    const fetchData = async () => {
        const child = await childrenService.getChild(id);
        setChild(child);

        await childrenService.resetTodo(child);
        await childrenService.calculateRecommendations(
            child["interventions_calc"]
        );

        const interventionDefinitions = await childrenService.getInterventions(
            child["interventions_set"],
            child["intervention_definitions_for_child"]
        );
        setActiveInterventions(interventionDefinitions["activeInterventions"]);
        setAllInterventions(
            interventionDefinitions["allInterventionDefinitions"]
        );
        const questionnaires = await childrenService.getQuestionnaires(
            child["questionnaires_set"]
        );
        setAllQuestionnaires(questionnaires);

        const cryLogEntriesUrl = child["crylogentries"];
        const sleepLogEntriesUrl = child["sleeplogentries"];
        const mealLogEntriesUrl = child["meallogentries"];
        const loadedActivityLogs = await childrenService.getAllActivityLogs(
            cryLogEntriesUrl,
            sleepLogEntriesUrl,
            mealLogEntriesUrl
        );
        setAllActivityLogs(loadedActivityLogs);

        const rhythmLogUrl = child["targetrhythms_set"];
        // console.log("RHYTHM LOG URL", rhythmLogUrl);
        const rhythmLog = await childrenService.getRhythmLog(rhythmLogUrl);
        // console.log("RHYTHM LOG", rhythmLog);
        const rhythmLogCreateDate = rhythmLog["create_time"];

        const rhythmPropositionLogUrl = rhythmLogUrl.replace(
            "targetrhythms",
            "targetrhythmproposition"
        );

        const rhythmPropositionLogs =
            await childrenService.getRhythmPropositionLogs(
                rhythmPropositionLogUrl
            );
        // console.log("RHYTHM PROPOSITION LOGS", rhythmPropositionLogs);
        setRhythmPropositionLogs(rhythmPropositionLogs);

        const messagesToParentsLogs =
            await childrenService.getAllMessagesToParent(
                child["messages_to_parents_set"]
            );
        setMessagesToParents(messagesToParentsLogs);

        const childPhaseUrl = child["child_phase"];
        const childPhase = await childrenService.getChildPhase(childPhaseUrl);
        setChildPhase(childPhase);

        const childNeedForSleepUrl = child["child_need_for_sleep"];
        const needForSleep =
            await childrenService.getChildNeedForSleep(childNeedForSleepUrl);
        setChildNeedForSleep(needForSleep);

        const childWellnessURL = child["wellness"];
        const childWellnessArray =
            await childrenService.getChildWellness(childWellnessURL);
        setChildWellness(childWellnessArray);

        const userWellnessURLs = child["caretakers_wellness_urls"];
        const userNames = child["caretakers_names"];
        let userWellnesss = [];
        let i = 0;
        for (const url of userWellnessURLs) {
            let name = userNames[i];
            let userWellness = await userService.getUserWellness(url);
            userWellnesss.push({
                name: name,
                wellness: userWellness,
            });
            i++;
        }
        setUserWellness(userWellnesss);

        if (rhythmLogCreateDate !== null) {
            const rhythmLogCreateDateObject = new Date(rhythmLogCreateDate);
            setRhythmDate(
                rhythmLogCreateDateObject.toLocaleDateString(
                    "de-DE",
                    dateFormat
                )
            );
        } else {
            setRhythmDate(null);
        }

        setRhythmLogs(rhythmLog["all_logs"]);

        const validDaysUrl = child["valid_days_url"];
        const end = new Date();
        const begin = new Date(new Date().setDate(end.getDate() - 30));
        const childDayValidation = await childrenService.getValidDays(
            validDaysUrl,
            begin,
            end
        );
        setValidDays(childDayValidation);
    };

    useEffect(() => {
        fetchData().then((r) => console.log(r));
    }, []);

    const openModal = (modalId) => {
        switch (modalId) {
            case 1:
                acceptedInterventionModalRef.current.handleOpen(true);
                break;
            case 2:
                vagueInterventionModalRef.current.handleOpen(true);
                break;
            case 3:
                deniedInterventionModalRef.current.handleOpen(true);
                break;
            default:
                alert("Modal does not exist!");
        }
    };

    if (child) {
        return (
            <div>
                {/* INFORMATION */}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <PaperElement>
                            <GeneralInformation
                                data={child}
                                allActivityLogs={allActivityLogs}
                                childPhase={childPhase}
                                needForSleep={childNeedForSleep}
                            />
                        </PaperElement>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <PaperElement>
                            <TwentyFourHoursProtocol
                                data={allActivityLogs}
                                rhythmPropositionLogs={rhythmPropositionLogs}
                                rhythmLogs={rhythmLogs}
                                validDays={validDays}
                                rhythmDate={rhythmDate}
                            />
                        </PaperElement>
                    </Grid>
                </Grid>

                {/* INTERVENTIONS */}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <PaperElement>
                            <AlgoRecommendation
                                title={t("algo.title.open")}
                                onChange={fetchData}
                                allInterventions={allInterventions}
                                child={child}
                                activeInterventions={activeInterventions}
                                setActiveInterventions={setActiveInterventions}
                                recommendedInterventions={activeInterventions?.filter(
                                    (i) => i.status === 0
                                )}
                                childPhase={childPhase}
                            />
                        </PaperElement>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <PaperElement>
                            <Title>
                                {t("algo.title.processed_interventions")}
                            </Title>

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4} lg={4}>
                                    <ModalElement
                                        ref={acceptedInterventionModalRef}
                                        background={"rgba(0, 180, 15, 0.21)"}
                                    >
                                        <ProcessedInterventions
                                            title={t("algo.title.accepted")}
                                            onChange={fetchData}
                                            hideProcessButtonId={1}
                                            data={activeInterventions?.filter(
                                                (i) => i.status === 1
                                            )}
                                        />
                                    </ModalElement>
                                    <Button
                                        sx={{
                                            width: "100%",
                                            color: "black",
                                            border: "1px solid rgba(0, 180, 15, 0.6)",
                                            backgroundColor:
                                                "rgba(0, 180, 15,  0.3)",
                                            "&:hover": {
                                                backgroundColor:
                                                    "rgba(0, 180, 15, 0.45)",
                                            },
                                        }}
                                        onClick={() => openModal(1)}
                                    >
                                        {t("algo.title.accepted")} (
                                        {
                                            activeInterventions?.filter(
                                                (i) => i.status === 1
                                            ).length
                                        }
                                        )
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <ModalElement
                                        ref={vagueInterventionModalRef}
                                        background={"rgba(255,165,0, 0.25)"}
                                    >
                                        <ProcessedInterventions
                                            title={t("algo.title.vague")}
                                            onChange={fetchData}
                                            hideProcessButtonId={2}
                                            data={activeInterventions?.filter(
                                                (i) => i.status === 2
                                            )}
                                        />
                                    </ModalElement>
                                    <Button
                                        sx={{
                                            width: "100%",
                                            color: "black",
                                            backgroundColor:
                                                "rgba(255,165,0, 0.3)",
                                            border: "1px solid rgba(255,165,0, 0.6)",
                                            "&:hover": {
                                                backgroundColor:
                                                    "rgba(255,165,0, 0.45)",
                                            },
                                        }}
                                        onClick={() => openModal(2)}
                                    >
                                        {t("algo.title.vague")} (
                                        {
                                            activeInterventions?.filter(
                                                (i) => i.status === 2
                                            ).length
                                        }
                                        )
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <ModalElement
                                        ref={deniedInterventionModalRef}
                                        background={"rgba(255, 150, 150, 0.3)"}
                                    >
                                        <ProcessedInterventions
                                            title={t("algo.title.denied")}
                                            onChange={fetchData}
                                            hideProcessButtonId={3}
                                            data={activeInterventions?.filter(
                                                (i) => i.status === 3
                                            )}
                                        />
                                    </ModalElement>
                                    <Button
                                        sx={{
                                            width: "100%",
                                            color: "black",
                                            backgroundColor:
                                                "rgba(232, 36, 12, 0.3)",
                                            border: "1px solid rgba(232, 36, 12, 0.6)",
                                            "&:hover": {
                                                backgroundColor:
                                                    "rgba(232, 36, 12, 0.45)",
                                            },
                                        }}
                                        onClick={() => openModal(3)}
                                    >
                                        {t("algo.title.denied")} (
                                        {
                                            activeInterventions?.filter(
                                                (i) => i.status === 3
                                            ).length
                                        }
                                        )
                                    </Button>
                                </Grid>
                            </Grid>
                        </PaperElement>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <PaperElement>
                            <MessageToGuardiansDisplay
                                child={child}
                                messagesToParents={messagesToParents}
                                setMessagesToParents={setMessagesToParents}
                            />
                        </PaperElement>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <PaperElement>
                            <QuestionnaireView
                                questionnaires={questionnaires}
                            ></QuestionnaireView>
                        </PaperElement>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <PaperElement>
                            <WellnessView
                                childWellness={childWellness}
                                userWellness={userWellness}
                            ></WellnessView>
                        </PaperElement>
                    </Grid>
                </Grid>
            </div>
        );
    } else {
        return (
            <div>
                <h1>{t("missing.information")}</h1>
            </div>
        );
    }
};

export default Dashboard;
