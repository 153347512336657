import axios from "axios";
import { requestService } from "./requestService";

// this is an enum in the backend, we use this to tell the backend whether the accept was made by the doctor or the system itself
const DOCTOR_GUIDED = 1;

export const childrenService = {
    getChildren,
    getChild,
    getInterventions,
    getInterventionHistory,
    calculateRecommendations,
    classifyIntervention,
    reclassifyIntervention,
    archiveMessage,
    getAllActivityLogs,
    getRhythmLog,
    getRhythmPropositionLogs,
    saveDoctorsNote,
    getAllMessagesToParent,
    sendMessageToParents,
    resetTodo,
    getQuestionnaires,
    getChildPhase,
    getChildNeedForSleep,
    getChildWellness,
    getValidDays,
};

// CHILD
async function getChildren() {
    const url = localStorage.getItem("childrenUrl");
    const config = requestService.authRequestConfig();
    const response = await axios.get(url, config);
    return response.data;
}

async function saveDoctorsNote(childUrl, updatedNote) {
    let config = requestService.authRequestConfig();
    const data = { doctors_notes: updatedNote };
    let response = await axios.patch(childUrl, data, config);
    return response.status;
}

async function resetTodo(child) {
    child["todo"] = 0;
    let config = requestService.authRequestConfig();
    return await axios.patch(child["url"], child, config);
}

async function archiveMessage(message) {
    let config = requestService.authRequestConfig();
    return await axios.patch(message["url"], message, config);
}

async function sendMessageToParents(messageToParentsUrl, message) {
    let config = requestService.authRequestConfig();
    return await axios.post(messageToParentsUrl, message, config);
}

async function getChild(childId) {
    const childrenUrl = localStorage.getItem("childrenUrl");
    let config = requestService.authRequestConfig();
    let response = await axios.get(childrenUrl, config);

    const relevantChild = response.data.filter(
        (child) => child.id.toString() === childId
    )[0];
    const childUrl = relevantChild["url"];

    const allCaretakerNames = [];
    const allCaretakerEmails = [];
    const allCaretakerWellnessURLs = [];
    for await (const caretaker of relevantChild["caretakers"]) {
        const response = await axios.get(caretaker["url"], config);
        allCaretakerNames.push(
            response.data.first_name + " " + response.data.last_name
        );
        allCaretakerEmails.push(response.data.email);
        allCaretakerWellnessURLs.push(response.data["wellness"]);
    }

    config = requestService.authRequestConfig();
    response = await axios.get(childUrl, config);
    response.data["caretakers_names"] = allCaretakerNames;
    response.data["caretakers_emails"] = allCaretakerEmails;
    response.data["caretakers_wellness_urls"] = allCaretakerWellnessURLs;
    return response.data;
}

// LOGS
async function getAllActivityLogs(
    cryLogEntriesUrl,
    sleepLogEntriesUrl,
    mealLogEntriesUrl
) {
    const [cryLogs, mealLogs, sleepLogs] = await Promise.all([
        getCryLogs(cryLogEntriesUrl),
        getMealLogs(mealLogEntriesUrl),
        getSleepLogs(sleepLogEntriesUrl),
    ]);
    return {
        cryLogs: cryLogs,
        mealLogs: mealLogs,
        sleepLogs: sleepLogs,
    };
}

async function getCryLogs(cryLogEntriesUrl) {
    const config = requestService.authRequestConfig();
    const response = await axios.get(cryLogEntriesUrl, config);
    return response.data;
}

async function getMealLogs(mealLogEntriesUrl) {
    const config = requestService.authRequestConfig();
    const response = await axios.get(mealLogEntriesUrl, config);
    return response.data;
}

async function getSleepLogs(sleepLogEntriesUrl) {
    const config = requestService.authRequestConfig();
    const response = await axios.get(sleepLogEntriesUrl, config);
    return response.data;
}

async function getRhythmLog(rhythmLogUrl) {
    const config = requestService.authRequestConfig();
    const response = await axios.get(rhythmLogUrl, config);
    if (!response || response.data.length === 0)
        return { all_logs: [], create_time: null };
    const createTime = response.data[0].create_time;
    const allLogs = [];
    for await (const logUrl of response.data[0]["logs"]) {
        const response = await axios.get(logUrl, config);
        allLogs.push(response.data);
    }

    return { all_logs: allLogs, create_time: createTime };
}

async function getRhythmPropositionLogs(rhythmPropositionLogsUrl) {
    const config = requestService.authRequestConfig();
    const response = await axios.get(rhythmPropositionLogsUrl, config);
    if (!response || response.data.length === 0)
        return { all_logs: [], create_time: null };
    const createTime = new Date();

    return { all_logs: response.data?.logs || [], create_time: createTime };
}

// INTERVENTIONS
async function getInterventions(
    interventionListUrl,
    allInterventionDefinitionsUrl
) {
    const config = requestService.authRequestConfig();

    let responseInterventionListData;

    const responseInterventionList = await axios.get(
        interventionListUrl,
        config
    );
    responseInterventionListData = responseInterventionList["data"];

    const allInterventionDefinitions = await axios.get(
        allInterventionDefinitionsUrl
    );

    let activeInterventions = [];
    for (let i = 0; i < responseInterventionListData.length; i++) {
        const interventionItem = responseInterventionListData[i];
        const interventionDefinitionUrl =
            interventionItem["intervention_definition"];
        const responseInterventionDefinition = await axios.get(
            interventionDefinitionUrl,
            config
        );
        responseInterventionDefinition["data"]["status"] =
            interventionItem["status"];
        responseInterventionDefinition["data"]["url"] = interventionItem["url"];
        responseInterventionDefinition["data"]["status_date"] =
            interventionItem["status_date"];
        responseInterventionDefinition["data"]["active_time_in_days"] =
            interventionItem["active_time_in_days"];
        responseInterventionDefinition["data"]["intervention_definition_url"] =
            interventionItem["intervention_definition"];
        responseInterventionDefinition["data"]["intervention_url"] =
            interventionItem["url"];
        activeInterventions.push(responseInterventionDefinition["data"]);
    }

    return {
        allInterventionDefinitions: allInterventionDefinitions.data.result,
        activeInterventions: activeInterventions,
    };
}

async function getInterventionHistory(interventionUrl) {
    const config = requestService.authRequestConfig();

    const responseInterventionHistory = await axios.get(
        `${interventionUrl}/history`,
        config
    );
    return responseInterventionHistory["data"];
}

async function calculateRecommendations(calculationUrl) {
    const config = requestService.authRequestConfig();
    let body = {
        datetime: new Date().toISOString(),
    };
    await axios.post(calculationUrl, body, config);
}

async function getAllMessagesToParent(messageToParentsUrl) {
    const config = requestService.authRequestConfig();
    const messageToParentsList = await axios.get(messageToParentsUrl, config);
    return messageToParentsList.data;
}

async function classifyIntervention(
    interventionUrl,
    newStatus,
    interventionDefinition,
    child
) {
    try {
        const data = {
            child: child,
            status: newStatus,
            status_date: new Date().toISOString(),
            result: 0,
            intervention_definition: interventionDefinition["url"],
            therapy_style: DOCTOR_GUIDED,
        };

        const config = requestService.authRequestConfig();
        const response = await axios.post(interventionUrl, data, config);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

async function reclassifyIntervention(interventionUrl, newStatus) {
    try {
        const data = { status: newStatus };
        const config = requestService.authRequestConfig();
        const response = await axios.patch(interventionUrl, data, config);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

async function getQuestionnaires(questionnaireUrl) {
    try {
        const response = await axios.get(questionnaireUrl);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

async function getChildPhase(childPhaseUrl) {
    try {
        const response = await axios.get(childPhaseUrl);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

async function getChildNeedForSleep(needForSleepUrl) {
    try {
        const response = await axios.get(needForSleepUrl);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

async function getChildWellness(childWellnessURL) {
    try {
        const response = await axios.get(childWellnessURL);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

async function getValidDays(validDaysURL, begin, end) {
    const config = requestService.authRequestConfig();
    config["params"] = {
        from_day: begin.toISOString(),
        to_day: end.toISOString(),
    };
    try {
        const response = await axios.get(validDaysURL, config);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}
