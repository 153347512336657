import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TextCollapse = (props) => {
    const { t } = useTranslation();

    const [text, setText] = useState("");
    const [collapse, setCollapse] = useState(true);
    const [alwaysExpand, setAlwaysExpand] = useState(false);
    const [maxLength, setMaxLength] = useState(
        props.maxLength ? props.maxLength : 120
    );

    useEffect(() => {
        if (props.text) setText(props.text);
        if (props.maxLength) setMaxLength(props.maxLength);
        if (props.showMoreFunction) setAlwaysExpand(true);
    }, [props]);

    const toggleAction = () => {
        if (props.showMoreFunction) {
            props.showMoreFunction();
        } else {
            setCollapse(!collapse);
        }
    };

    return collapse ? (
        <div>
            {text.substring(0, maxLength)}{" "}
            {text.substring(0, maxLength).length !== text.length ||
            alwaysExpand ? (
                <span>
                    ...
                    <span
                        style={{
                            cursor: "pointer",
                            color: "cornflowerblue",
                            fontSize: "0.9em",
                            marginLeft: "2px",
                        }}
                        onClick={toggleAction}
                    >
                        {" "}
                        {t("collapse.show-more")}
                    </span>
                </span>
            ) : null}
        </div>
    ) : (
        <div>
            {text}
            <br />{" "}
            <span
                style={{
                    cursor: "pointer",
                    color: "cornflowerblue",
                    fontSize: "0.9em",
                    marginLeft: "2px",
                }}
                onClick={toggleAction}
            >
                {t("collapse.show-less")}
            </span>
        </div>
    );
};

export default TextCollapse;
