import React, { useEffect, useState } from "react";
import Title from "../../Elements/Title";
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from "recharts";

export const WellnessView = (props) => {
    const [wellnessData, setWellnessData] = useState([]);
    const [userKeys, setUserKeys] = useState([]);

    useEffect(() => {
        setWellnessData(transformList());
    }, [props]);

    const transformList = () => {
        let data = [];

        const hasDate = (date) => {
            let i = 0;
            for (const alreadyAddedDate of data) {
                if (
                    date.toDateString() ===
                    alreadyAddedDate["create_time"].toDateString()
                ) {
                    return i;
                }
                i++;
            }
            return false;
        };

        props.childWellness.forEach((wellness) => {
            let create_date = new Date(wellness.create_time);
            let alreadyHasDate = hasDate(create_date);

            if (alreadyHasDate !== false) {
                let entryForDate = data[alreadyHasDate];
                entryForDate["Kinder Wellness"] = wellness["wellness"];
                data[alreadyHasDate] = entryForDate;
                return;
            }

            let entryForDate = {
                create_time: create_date,
                "Kinder Wellness": wellness["wellness"],
            };

            data.push(entryForDate);
        });

        let userKeys = [];
        props.userWellness.forEach((user) => {
            userKeys.push(user.name);
            if (!user.wellness) return;
            return user.wellness.forEach((wellness) => {
                let create_date = new Date(wellness.create_time);
                let alreadyHasDate = hasDate(create_date);

                if (alreadyHasDate !== false) {
                    let entryForDate = data[alreadyHasDate];
                    entryForDate[user.name] = wellness["wellness"];
                    data[alreadyHasDate] = entryForDate;
                    return;
                }

                let userKey = `${user.name}`;
                let entryForDate = {
                    create_time: create_date,
                };
                entryForDate[userKey] = wellness["wellness"];

                data.push(entryForDate);
            });
        });
        setUserKeys(userKeys);

        data = data
            .sort((a, b) => a["create_time"] - b["create_time"])
            .map((entry) => {
                entry["create_time"] =
                    entry["create_time"].toLocaleDateString();
                return entry;
            });

        return data;
    };

    const colors = [
        "#ff55fc", // Soft Red
        "#5555FF", // Soft Blue
        "#FFA570", // Soft Orange
        "#944294", // Soft Purple
        "#D6D68C", // Soft Yellow
        "#55FFFF", // Soft Cyan
        "#FF8C99", // Soft Pink
        "#559955", // Softer Green
        "#4C77B5", // Softer Blue
    ];

    let i = 0;
    const getRandomHexColor = () => colors[i++ % colors.length];

    return (
        <div>
            <Title
                sx={{
                    display: "inline",
                    width: "40%",
                }}
            >
                Wellness
            </Title>

            <LineChart
                width={1000}
                height={250}
                data={wellnessData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="create_time" />
                <YAxis domain={[0, 10]} dataKey="wellness" />
                <Legend />
                <Line
                    type="natural"
                    dataKey="Kinder Wellness"
                    stroke={getRandomHexColor()}
                    connectNulls={true}
                />
                $
                {userKeys.map((key) => (
                    <Line
                        type="natural"
                        dataKey={key}
                        stroke={getRandomHexColor()}
                        connectNulls={true}
                    />
                ))}
            </LineChart>
        </div>
    );
};
