import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

const IconButtonElement = styled(IconButton)(({ theme }) => ({
    marginTop: "-10px",
    marginLeft: "10px",
    backgroundColor: "white",
    border: "rgba(63, 81, 181, 0.9) 1px solid",
    color: "rgba(63, 81, 181, 0.9)",
    "&:hover": {
        backgroundColor: "rgba(63, 81, 181, 0.9)",
        color: "white",
    },
}));

export default IconButtonElement;
