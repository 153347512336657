import TextField from "@mui/material/TextField";
import * as React from "react";
import { useEffect, useState } from "react";
import { authenticationService } from "../../services/AuthenticationService";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import { ListItem } from "@mui/material";

/**
 * This Component provides the Login logic. There is no registration logic implement yet.
 *
 * @return {JSX.Element}
 */
const PerformPasswordReset = () => {
    let { t, i18n } = useTranslation();

    const [newPassword, setNewPassword] = useState("");
    const [newPasswordErrors, setNewPasswordErrors] = useState([]);

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [failAlertOpen, setFailAlertOpen] = React.useState(false);

    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");

    const handleClick = (e) => {
        e.preventDefault();
        authenticationService
            .performPasswordRest(newPassword, token)
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    setSuccessAlertOpen(true);
                    setFailAlertOpen(false);
                } else if (response.status === 400) {
                    setNewPasswordErrors(response.data["password"]);
                    setSuccessAlertOpen(false);
                    setFailAlertOpen(true);
                } else {
                    setNewPasswordErrors([t("reset_pw.link_expired_title")]);
                    setSuccessAlertOpen(false);
                    setFailAlertOpen(true);
                }
            });
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                height: "65vh",
                marginTop: "150px",
                maxWidth: "60%",
                marginLeft: "auto",
                marginRight: "auto",
            }}
        >
            <form onSubmit={handleClick}>
                <h1>{t("reset_pw.choose_new_password_title")}</h1>
                <Typography style={{ marginBottom: "10px" }}>
                    {t("reset_pw.choose_new_password_message")}
                </Typography>
                <div style={{ marginBottom: "10px", marginRight: "150px" }}>
                    <TextField
                        fullWidth={true}
                        label={t("reset_pw.new_password")}
                        type="password"
                        variant="standard"
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>
                <div>
                    <Box pt={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {t("reset_pw.password_reset_title")}
                        </Button>
                    </Box>
                </div>
                <Box sx={{ width: "50%" }}>
                    <Collapse in={successAlertOpen}>
                        <div style={{ marginTop: "30px" }}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        size="small"
                                        onClick={() =>
                                            setSuccessAlertOpen(false)
                                        }
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                <span>
                                    {t("reset_pw.success_perform_reset")}{" "}
                                </span>
                                <a href="/login" style={{ color: "#3f51b5" }}>
                                    {t("reset_pw.back_to_login")}
                                </a>
                            </Alert>
                        </div>
                    </Collapse>
                </Box>
                <Box sx={{ width: "50%" }}>
                    <Collapse in={failAlertOpen}>
                        <div style={{ marginTop: "30px" }}>
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        size="small"
                                        onClick={() => setFailAlertOpen(false)}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                <span>{t("reset_pw.error_perform_reset")}</span>
                                {newPasswordErrors.map((error) => {
                                    return <li>{error}</li>;
                                })}
                            </Alert>
                        </div>
                    </Collapse>
                </Box>
            </form>
        </div>
    );
};

export default PerformPasswordReset;
