import React, { useEffect, useState } from "react";
import axios from "axios";
import Title from "../../Elements/Title";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import CheckIcon from "@material-ui/icons/Check";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

export const QuestionView = (props) => {
    const [question, setQuestion] = useState();
    const [definition, setDefinition] = useState();
    const [selectedAnswers, setSelectedAnswers] = useState();
    const [allAnswers, setAllAnswers] = useState();

    useEffect(() => {
        if (props.questionUrl && !question) {
            axios.get(props.questionUrl).then((response) => {
                const question = response.data;
                setQuestion(question);
                axios.get(question.definition).then((response) => {
                    const definition = response.data;
                    setDefinition(definition);
                    let possibleAnswers = definition["answers"];
                    let possibleAnswersElements = [];
                    let pos = possibleAnswers.map((possibleAnswer) => {
                        return axios.get(possibleAnswer);
                    });

                    Promise.all(pos).then((posAnss) => {
                        posAnss.forEach((response) => {
                            let option = response.data;
                            possibleAnswersElements.push(option);
                        });
                        setAllAnswers(possibleAnswersElements);
                    });
                });
                axios.get(question["selected_answers_set"]).then((response) => {
                    const answers = response.data;
                    let selectedAnswersElements = [];
                    let responses = answers.map((answer) => {
                        return axios.get(answer["answer_option"]);
                    });
                    Promise.all(responses).then((responsesAwaited) => {
                        responsesAwaited.forEach((response) => {
                            let option = response.data;
                            selectedAnswersElements.push({ ...option });
                        });
                    });
                    setSelectedAnswers(selectedAnswersElements);
                });
            });
        }
    }, [props]);

    if (definition && allAnswers) {
        return (
            <div>
                <h5>Frage: {definition.name}</h5>
                <div>{definition.text}</div>
                <div>
                    {selectedAnswers ? (
                        <div>
                            <h5>Ausgewählte Antworten</h5>
                            {selectedAnswers?.map((answer) => {
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            direction: "row",
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                fontSize: 15,
                                                color: "#3f51b5",
                                                marginRight: "5px",
                                                position: "relative",
                                                bottom: "-3px",
                                            }}
                                        />
                                        <div>{answer.text}</div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div></div>
                    )}
                    <div>
                        <h5>Mögliche Antworten</h5>
                        <div style={{ display: "flex", direction: "row" }}>
                            {allAnswers?.map((answer) => {
                                return (
                                    <div
                                        style={{
                                            marginRight: "5px",
                                            display: "flex",
                                            direction: "row",
                                        }}
                                    >
                                        <RadioButtonUncheckedIcon
                                            sx={{
                                                fontSize: 10,
                                                color: "#3f51b5",
                                                marginRight: "5px",
                                                position: "relative",
                                            }}
                                        />
                                        <div style={{ color: "grey" }}>
                                            {answer.text}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function formatDate(dateString) {
        return new Date(dateString).toLocaleDateString("en-GB");
    }
};
