import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const ScrollbarElement = styled("div")(({ theme }) => ({
    width: "100%",
    overflowY: "scroll",
    overflowX: "hidden",

    "::-webkit-scrollbar": {
        width: "12px",
    },

    "::-webkit-scrollbar-track": {
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "10px",
    },

    "::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.5)",
        background: "rgba(63, 81, 181, 0.9)",
    },
}));

export default ScrollbarElement;
