import React, { Suspense } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import ChildrenOverview from "./components/Home/ChildrenOverview";
import Dashboard from "./components/Dashboard/Dashboard";
import { useTranslation } from "react-i18next";
import logo from "./logo.svg";
import Login from "./components/Login/Login";
import Header from "./Header";
import { Provider } from "react-redux";
import store from "./store";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import PasswordChange from "./components/PasswordChange/PasswordChange";
import RequestPasswordReset from "./components/PasswordReset/RequestPasswordReset";
import PerformPasswordReset from "./components/PasswordReset/PerformPasswordReset";
import ButtonMailto from "./Elements/ButtonMailto";

const customTheme = createTheme();

function Copyright() {
    const { t, i18n } = useTranslation();

    return (
        <span>
            <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © 2020 – 2021 by "}
                <Link
                    color="inherit"
                    href="https://baby-sleep-doctor.ch/"
                    target="_blank"
                >
                    baby-sleep-doctor GmbH
                </Link>
                , CH-4314 Zeiningen
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                {t("footer_mailto_text")}{" "}
                <ButtonMailto
                    label={"ogemperle@gmx.net"}
                    mailto={"mailto:ogemperle@gmx.net"}
                />
            </Typography>
        </span>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: "100px",
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
}));

const App = () => {
    const classes = useStyles();

    const Loader = () => (
        <div className="App">
            <img src={logo} alt="logo" />
            <div>loading...</div>
        </div>
    );

    return (
        <Suspense fallback={<Loader />}>
            <Provider store={store}>
                <BrowserRouter>
                    <ThemeProvider theme={customTheme}>
                        <CssBaseline />
                        <div className={classes.root}>
                            <Header />
                            <main className={classes.content}>
                                <div className={classes.appBarSpacer} />
                                <Container
                                    maxWidth="lg"
                                    className={classes.container}
                                >
                                    <Routes>
                                        <Route path="" element={<Login />} />
                                        <Route
                                            path="home"
                                            element={<ChildrenOverview />}
                                        />
                                        <Route
                                            path="dashboard/:id"
                                            element={<Dashboard />}
                                        />
                                        <Route
                                            path="login"
                                            element={<Login />}
                                        />
                                        <Route
                                            path="PasswordChange"
                                            element={<PasswordChange />}
                                        />
                                        <Route
                                            path="RequestPasswordReset"
                                            element={<RequestPasswordReset />}
                                        />
                                        <Route
                                            path="PasswordReset"
                                            element={<PerformPasswordReset />}
                                        />
                                    </Routes>
                                    <Box pt={4}>
                                        <Copyright />
                                    </Box>
                                </Container>
                            </main>
                        </div>
                    </ThemeProvider>
                </BrowserRouter>
            </Provider>
        </Suspense>
    );
};

export default App;
