import React from "react";
import Title from "../../Elements/Title";
import TextField from "@mui/material/TextField";
import { childrenService } from "../../services/childrenService";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import MainButton from "../../Elements/MainButton";
import DangerButton from "../../Elements/DangerButton";

/**
 * This Component provides the Create Functionality for the Direct Messages From Doctors to Guardians.
 * @param props Props
 *
 * @return {JSX.Element}
 */
const MessageToGuardiansCreate = (props) => {
    const { t } = useTranslation();

    const [subject, setSubject] = React.useState(props.currentText);
    const [subjectError, setSubjectError] = React.useState("");

    const [value, setValue] = React.useState(props.currentText);
    const [valueError, setValueError] = React.useState("");

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [failAlertOpen, setFailAlertOpen] = React.useState(false);
    const [sendButtonDisabled, setSendButtonDisabled] = React.useState(false);

    function* entries(obj) {
        for (let key of Object.keys(obj)) {
            yield [key, obj[key]];
        }
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleChange2 = (event) => {
        setSubject(event.target.value);
    };

    async function sendMessage() {
        const message = {
            create_time: new Date().toISOString(),
            title: subject,
            message_content: value,
            status: 0,
            child: props.child["url"],
        };

        let subjectErr = "";
        let valueErr = "";

        const response = await childrenService.sendMessageToParents(
            props.child["messages_to_parents_set"],
            message
        );

        if (response.status === 201) {
            setSendButtonDisabled(true);
            const messagesToParentsLogs =
                await childrenService.getAllMessagesToParent(
                    props.child["messages_to_parents_set"]
                );
            props.setMessagesToParents(messagesToParentsLogs);

            setSuccessAlertOpen(true);
        } else {
            for (let [key, value] of entries(response.data)) {
                if (key === "message_content") valueErr += value;
                if (key === "title") subjectErr += value;
            }
            setFailAlertOpen(true);
            setValueError(valueErr);
            setSubjectError(subjectErr);
        }
    }

    return (
        <div style={{ padding: "30px", minHeight: "600px", overflow: "auto" }}>
            <Title>Nachricht an Erziehungsberechtigte versenden</Title>

            {subjectError === "" ? (
                <TextField
                    id="outlined-multiline-static"
                    label={"Betreff"}
                    multiline
                    rows={1}
                    style={{ width: "100%", marginTop: "10px" }}
                    onChange={handleChange2}
                    defaultValue={subject}
                />
            ) : (
                <TextField
                    label={"Betreff"}
                    multiline
                    rows={1}
                    helperText={subjectError}
                    id="outlined-error-helper-text"
                    error
                    style={{ width: "100%", marginTop: "10px" }}
                    onChange={handleChange2}
                    defaultValue={subject}
                />
            )}

            {valueError === "" ? (
                <TextField
                    id="outlined-multiline-static"
                    label={"Nachricht"}
                    multiline
                    rows={10}
                    style={{ width: "100%", marginTop: "10px" }}
                    onChange={handleChange}
                    defaultValue={value}
                />
            ) : (
                <TextField
                    label={"Nachricht"}
                    multiline
                    rows={10}
                    helperText={valueError}
                    id="outlined-error-helper-text"
                    error
                    style={{ width: "100%", marginTop: "10px" }}
                    onChange={handleChange}
                    defaultValue={value}
                />
            )}

            <MainButton
                onClick={() => sendMessage()}
                disabled={sendButtonDisabled}
            >
                {"Versenden"}
            </MainButton>
            <DangerButton onClick={() => props.closeModal()}>
                {t("close")}
            </DangerButton>
            <Box sx={{ width: "100%", marginTop: "10px" }}>
                <Collapse in={successAlertOpen}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                size="small"
                                onClick={() => setSuccessAlertOpen(false)}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {
                            "Die Nachricht wurde erfolgreich an die Erziehungsberechtigten versendet."
                        }
                    </Alert>
                </Collapse>
            </Box>

            <Box sx={{ width: "100%", marginTop: "10px" }}>
                <Collapse in={failAlertOpen}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                size="small"
                                onClick={() => setFailAlertOpen(false)}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {
                            "Etwas ist schiefgelaufen. Die Nachricht konnte nicht versendet werden."
                        }
                    </Alert>
                </Collapse>
            </Box>
        </div>
    );
};

export default MessageToGuardiansCreate;
