import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn, logout } from "./reducers/user";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { authenticationService } from "./services/AuthenticationService";
import { localStorageService } from "./services/LocalStorageService";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24,
    },
    appBar: {
        height: 65,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    title: {
        flexGrow: 1,
    },
    rightMenuBox: {
        position: "absolute",
        right: 0,
        marginRight: "20px",
    },
    menuItem: {
        color: "white",
    },
    inlineMenuItem: {
        display: "inline",
    },
}));

const Header = () => {
    let isLoggedIn_ = useSelector(isLoggedIn);
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const changeLanguage = (lng) => {
        localStorageService.setCurrentLanguage(lng);
        i18n.changeLanguage(lng);
    };

    const logoff = () => {
        dispatch(logout());
        authenticationService.logout();
        navigate("/login");
    };

    return (
        <div>
            <AppBar position="absolute" className={clsx(classes.appBar)}>
                <Toolbar className={classes.toolbar}>
                    <ReactLink
                        to={"/home"}
                        style={{ color: "white", textDecoration: "inherit" }}
                    >
                        <Typography
                            component="h1"
                            variant="h6"
                            noWrap
                            className={clsx(classes.title, classes.menuItem)}
                        >
                            {t("title")}
                        </Typography>
                    </ReactLink>
                    <div className={clsx(classes.rightMenuBox)}>
                        <MenuItem
                            className={clsx(
                                classes.inlineMenuItem,
                                classes.menuItem
                            )}
                            onClick={() => changeLanguage("de")}
                        >
                            de
                        </MenuItem>
                        <MenuItem
                            className={clsx(
                                classes.inlineMenuItem,
                                classes.menuItem
                            )}
                            onClick={() => changeLanguage("en")}
                        >
                            en
                        </MenuItem>
                        {isLoggedIn_ ? (
                            <MenuItem
                                className={clsx(
                                    classes.inlineMenuItem,
                                    classes.menuItem
                                )}
                                onClick={() => navigate("/passwordchange")}
                            >
                                {t("change_pw.title")}
                            </MenuItem>
                        ) : null}
                        {isLoggedIn_ ? (
                            <MenuItem
                                className={clsx(
                                    classes.inlineMenuItem,
                                    classes.menuItem
                                )}
                                onClick={() => logoff()}
                            >
                                {t("button.logout")}
                            </MenuItem>
                        ) : null}
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Header;
