import Button from "@mui/material/Button";
import React from "react";
import { styled } from "@mui/material/styles";

const MainButton = styled(Button)(({ theme }) => ({
    color: "white",
    marginTop: "20px",
    backgroundColor: "rgba(63, 81, 181, 0.9)",
    border: "1px solid rgba(63, 81, 181, 1)",
    "&:hover": {
        backgroundColor: "rgba(63, 81, 181, 0.7)",
        color: "white",
    },
}));

export default MainButton;
