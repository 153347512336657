import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { useEffect, useState } from "react";
import InterventionHistory from "./InterventionHistory";

function InterventionMenuButtons(props) {
    useEffect(() => {
        if (props.selectedIntervention === props.intervention.url) {
            props.setIsOpen(true);
        }
    }, [props.isOpen, props.intervention, props.intervention.status, props]);
    return (
        <div>
            <MenuItem
                style={{
                    display: "inline-block",
                }}
            >
                <MenuOpenIcon
                    onClick={() => {
                        props.setSelectedIntervention("");
                        props.setIsOpen(false);
                    }}
                ></MenuOpenIcon>
            </MenuItem>
            <MenuItem
                onClick={() =>
                    props.classifyIntervention(
                        props.intervention["update_url"],
                        1,
                        props.index
                    )
                }
                style={{
                    display: "inline-block",
                    padding: "5px",
                }}
            >
                {props.intervention.status === 1 ? (
                    <CheckIcon
                        style={{
                            background: props.statusColorMap[1],
                            color: "white",
                            borderRadius: "20px",
                            padding: "3px",
                        }}
                    />
                ) : (
                    <CheckIcon
                        style={{
                            color: props.statusColorMap[1],
                        }}
                    />
                )}
            </MenuItem>
            <MenuItem
                onClick={() =>
                    props.classifyIntervention(
                        props.intervention["update_url"],
                        2,
                        props.index
                    )
                }
                style={{
                    display: "inline-block",
                    padding: "5px",
                }}
            >
                {props.intervention.status === 2 ? (
                    <RadioButtonUncheckedIcon
                        style={{
                            background: props.statusColorMap[2],
                            color: "white",
                            borderRadius: "20px",
                            padding: "3px",
                        }}
                    />
                ) : (
                    <RadioButtonUncheckedIcon
                        style={{
                            color: props.statusColorMap[2],
                        }}
                    />
                )}
            </MenuItem>
            <MenuItem
                onClick={() =>
                    props.classifyIntervention(
                        props.intervention["update_url"],
                        3,
                        props.index
                    )
                }
                style={{
                    display: "inline-block",
                    padding: "5px",
                }}
            >
                {props.intervention.status === 3 ? (
                    <ClearIcon
                        style={{
                            background: props.statusColorMap[3],
                            color: "white",
                            borderRadius: "20px",
                            padding: "3px",
                        }}
                    />
                ) : (
                    <ClearIcon
                        style={{
                            color: props.statusColorMap[3],
                        }}
                    />
                )}
            </MenuItem>
            {props.intervention?.original_url?.length > 0 ? (
                <MenuItem
                    style={{
                        display: "inline-block",
                        padding: "5px",
                    }}
                >
                    <InterventionHistory
                        intervention={props.intervention}
                        statusColorMap={props.statusColorMap}
                    />
                </MenuItem>
            ) : null}
        </div>
    );
}

export default function InterventionMenu(props) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            {isOpen ? (
                <InterventionMenuButtons setIsOpen={setIsOpen} {...props} />
            ) : (
                <MenuItem>
                    <MenuIcon
                        onClick={() => {
                            props.setSelectedIntervention(
                                props.intervention.url
                            );
                            setIsOpen(true);
                        }}
                    ></MenuIcon>
                </MenuItem>
            )}
        </>
    );
}

InterventionMenu.propTypes = {
    onClick: PropTypes.func,
    index: PropTypes.number.isRequired,
    classifyIntervention: PropTypes.func.isRequired,
    intervention: PropTypes.any,
    setSelectedIntervention: PropTypes.func.isRequired,
    selectedIntervention: PropTypes.any,
    statusColorMap: PropTypes.shape({
        0: PropTypes.string,
        1: PropTypes.string,
        2: PropTypes.string,
        3: PropTypes.string,
    }),
};
