import React, { useEffect, useRef, useState } from "react";
import Title from "../../Elements/Title";
import { useTranslation } from "react-i18next";
import {
    Circle,
    Group,
    Image,
    Layer,
    Line,
    Rect,
    Stage,
    Text,
} from "react-konva";
import useImage from "use-image";
import ScrollbarElement from "../../Elements/ScrollbarElement";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButtonElement from "../../Elements/IconButtonElement";
import TargetRhythm from "./TargetRhythm";

/**
 * This Component provides the TwentyFourHoursProtocol for the Child Dashboard.
 *
 * @param props Props must provide the Child Information.
 *
 * @return {JSX.Element}
 */
const TwentyFourHoursProtocol = (props) => {
    const { t } = useTranslation();

    const [width, setWidth] = useState(100);

    const [allDatesToDisplay, setAllDatesToDisplay] = useState([]);
    const [validDays, setValidDays] = useState([]);
    const [allDatesDt, setAllDatesDt] = useState([]);
    const [allHoursOfDay, setAllHoursOfDay] = useState([]);
    const [sleepLogsSpans, setSleepLogsSpans] = useState([]);
    const [cryLogsSpans, setCryLogsSpans] = useState([]);
    const [sleepLogsActionPoints, setSleepLogsActionPoints] = useState([]);
    const [mealLogsActionPoints, setMealLogsActionPoints] = useState([]);
    const [mealLogsActionPointTypes, setMealLogsActionPointTypes] = useState(
        []
    );
    const [orderAsc, setOrderAsc] = useState(true);

    const demoRef = useRef();

    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            setWidth(event[0].contentBoxSize[0].inlineSize - 110);
        });

        if (demoRef) {
            resizeObserver.observe(demoRef.current);
        }
    }, [demoRef]);

    function daysIntoYear(date) {
        return (
            (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
                Date.UTC(date.getFullYear(), 0, 0)) /
            24 /
            60 /
            60 /
            1000
        );
    }

    function isWeekend(date) {
        let dayOfWeek = date.getDay();
        return dayOfWeek === 6 || dayOfWeek === 0;
    }

    function getWeekday(date) {
        let dayOfWeek = date.getDay();
        switch (dayOfWeek) {
            case 0:
                return "So";
            case 1:
                return "Mo";
            case 2:
                return "Di";
            case 3:
                return "Mi";
            case 4:
                return "Do";
            case 5:
                return "Fr";
            case 6:
                return "Sa";
            default:
                return "";
        }
    }

    const dateFormat = { year: "2-digit", month: "2-digit", day: "2-digit" };

    const widthPercentageDateDisplay = 0.12;
    const widthPercentageProtocolDisplay = 1 - widthPercentageDateDisplay;
    const rowHeight = 36;
    const topMargin = 20;
    const topMarginMain = 3;

    const zeroPad = (num, places) => String(num).padStart(places, "0");

    function calcXFromDate(date) {
        return (
            widthPercentageDateDisplay * width +
            ((date.getHours() * 60 + date.getMinutes()) / (60 * 24)) *
                widthPercentageProtocolDisplay *
                width
        );
    }

    function calcYFromDate(date) {
        if (allDatesDt[0] === undefined) return 0;
        let diff =
            new Date(
                date.getYear(),
                date.getMonth(),
                date.getDate()
            ).getTime() -
            new Date(
                allDatesDt[0].getYear(),
                allDatesDt[0].getMonth(),
                allDatesDt[0].getDate()
            ).getTime();
        let row = parseInt(diff / (24 * 3600 * 1000));
        if (orderAsc) {
            return row * rowHeight + 0.5 * rowHeight + topMarginMain;
        } else {
            return (
                (allDatesToDisplay.length - 1 - row) * rowHeight +
                0.5 * rowHeight +
                topMarginMain
            );
        }
    }

    function getCosCurveForLegend(leftMargin) {
        let points = [];
        let xCos = 0;
        while (xCos < 20) {
            const y_ = 6 * Math.cos(xCos);
            points.push(xCos + widthPercentageDateDisplay * width + leftMargin);
            points.push(y_ + 20);
            xCos += (Math.PI * 2) / 15;
        }

        return points;
    }

    function calcCosArrayFromDate(sleepLogsSpan) {
        let pointerDate = new Date(sleepLogsSpan[0]);
        let endDate = sleepLogsSpan[1];

        let points = [];
        let xCos = 0;

        while (pointerDate < endDate) {
            const x_ = calcXFromDate(pointerDate);
            const y_ = calcYFromDate(pointerDate) + 6 * Math.cos(xCos);
            points.push(x_);
            points.push(y_);
            xCos += (Math.PI * 2) / 15;
            pointerDate.setMinutes(pointerDate.getMinutes() + 1);
        }

        return points;
    }

    useEffect(() => {
        if (props.data) {
            const minCryLog = new Date(
                Math.min.apply(
                    null,
                    props.data["cryLogs"].map((x) => {
                        return new Date(x["begin_time"]);
                    })
                )
            );
            const maxCryLog = new Date(
                Math.max.apply(
                    null,
                    props.data["cryLogs"].map((x) => {
                        return new Date(x["end_time"]);
                    })
                )
            );

            const minMealLogs = new Date(
                Math.min.apply(
                    null,
                    props.data["mealLogs"].map((x) => {
                        return new Date(x["timestamp"]);
                    })
                )
            );
            const maxMealLogs = new Date(
                Math.max.apply(
                    null,
                    props.data["mealLogs"].map((x) => {
                        return new Date(x["timestamp"]);
                    })
                )
            );

            const minSleepLog = new Date(
                Math.min.apply(
                    null,
                    props.data["sleepLogs"].map((x) => {
                        return new Date(x["begin_time"]);
                    })
                )
            );
            const maxSleepLog = new Date(
                Math.max.apply(
                    null,
                    props.data["sleepLogs"].map((x) => {
                        return new Date(x["end_time"]);
                    })
                )
            );

            let minLogEntries = [minCryLog, minMealLogs, minSleepLog].filter(
                (d) => d instanceof Date && !isNaN(d)
            );
            let maxLogEntries = [maxCryLog, maxMealLogs, maxSleepLog].filter(
                (d) => d instanceof Date && !isNaN(d)
            );

            const minLog = new Date(Math.min.apply(null, minLogEntries));
            const maxLog = new Date(Math.max.apply(null, maxLogEntries));

            minLog.setHours(minLog.getHours());
            maxLog.setHours(maxLog.getHours());
            maxLog.setDate(maxLog.getDate() + 1);

            let datesToDisplay = [];
            let datesToDisplayDt = [];
            let ii = 0;

            while (minLog <= maxLog) {
                datesToDisplay.push(
                    minLog.toLocaleDateString("de-DE", dateFormat)
                );
                datesToDisplayDt.push(new Date(minLog));
                minLog.setDate(minLog.getDate() + 1);
                ii += 1;
            }
            setAllDatesToDisplay(datesToDisplay);
            setAllDatesDt(datesToDisplayDt);

            let startTime = new Date("2019-01-01");
            let endTime = new Date("2019-01-02");
            const allHoursOfDayToDisplay = [];
            while (startTime < endTime) {
                allHoursOfDayToDisplay.push(new Date(startTime));
                startTime.setMinutes(startTime.getMinutes() + 15);
            }

            setAllHoursOfDay(allHoursOfDayToDisplay);

            const sleepLogsSpansToDisplay = [];
            const sleepLogsActionPointsToDisplay = [];
            for (let i in props.data["sleepLogs"]) {
                let sleepLog = props.data["sleepLogs"][i];
                let startTime = new Date(sleepLog["begin_time"]);
                startTime.setHours(startTime.getHours() - 6);
                let endTime = new Date(sleepLog["end_time"]);
                endTime.setHours(endTime.getHours() - 6);

                sleepLogsActionPointsToDisplay.push(startTime);
                sleepLogsActionPointsToDisplay.push(endTime);

                if (startTime.getDate() === endTime.getDate()) {
                    sleepLogsSpansToDisplay.push([startTime, endTime]);
                } else {
                    sleepLogsSpansToDisplay.push([
                        startTime,
                        new Date(
                            startTime.getFullYear(),
                            startTime.getMonth(),
                            startTime.getDate(),
                            23,
                            59
                        ),
                    ]);
                    sleepLogsSpansToDisplay.push([
                        new Date(
                            endTime.getFullYear(),
                            endTime.getMonth(),
                            endTime.getDate(),
                            0,
                            0
                        ),
                        endTime,
                    ]);
                }
            }

            const cryLogsSpansToDisplay = [];
            for (let i in props.data["cryLogs"]) {
                let sleepLog = props.data["cryLogs"][i];
                let startTime = new Date(sleepLog["begin_time"]);
                startTime.setHours(startTime.getHours() - 6);
                let endTime = new Date(sleepLog["end_time"]);
                endTime.setHours(endTime.getHours() - 6);

                if (startTime.getDate() === endTime.getDate()) {
                    cryLogsSpansToDisplay.push([startTime, endTime]);
                } else {
                    cryLogsSpansToDisplay.push([
                        startTime,
                        new Date(
                            startTime.getFullYear(),
                            startTime.getMonth(),
                            startTime.getDate(),
                            23,
                            59
                        ),
                    ]);
                    cryLogsSpansToDisplay.push([
                        new Date(
                            endTime.getFullYear(),
                            endTime.getMonth(),
                            endTime.getDate(),
                            0,
                            0
                        ),
                        endTime,
                    ]);
                }
            }

            const mealLogsActionPointsToDisplay = [];
            const mealLogsActionPointTypesToDisplay = [];
            for (let i in props.data["mealLogs"]) {
                let mealLog = props.data["mealLogs"][i];
                let timestamp = new Date(mealLog["timestamp"]);
                timestamp.setHours(timestamp.getHours() - 6);
                mealLogsActionPointsToDisplay.push(timestamp);
                mealLogsActionPointTypesToDisplay.push(mealLog["type"]);
            }
            setMealLogsActionPoints(mealLogsActionPointsToDisplay);
            setMealLogsActionPointTypes(mealLogsActionPointTypesToDisplay);
            setCryLogsSpans(cryLogsSpansToDisplay);
            setSleepLogsSpans(sleepLogsSpansToDisplay);
            setSleepLogsActionPoints(sleepLogsActionPointsToDisplay);
        }
    }, [props.data]);

    useEffect(() => {
        setValidDays(props["validDays"]);
    }, [props.validDays]);

    const [waterTriangle] = useImage("/water_triangle.svg");
    const [mealTriangle] = useImage("/meal_triangle.svg");
    const [milkTriangle] = useImage("/milk_triangle.svg");
    const [breastTriangle] = useImage("/breast_triangle.svg");
    const [genericTriangle] = useImage("/triangle.svg");
    const [validImage] = useImage("/valid.svg");
    const [invalidImage] = useImage("/not_valid.svg");
    const images = [
        breastTriangle,
        milkTriangle,
        waterTriangle,
        mealTriangle,
        genericTriangle,
    ];
    const mealTypes = [
        "Brust",
        "Milch",
        "Wasser",
        "Andere",
        "Zielrhythmus Essen",
    ];

    const renderValidDayBox = (day, i, fill) => {
        const findMatchingValidDay = () => {
            return validDays?.find((value) => {
                let maybeMatchingDay = new Date(value.day).toLocaleDateString(
                    "de-DE",
                    dateFormat
                );
                return maybeMatchingDay === day;
            });
        };

        const minutesToString = (minutes) => {
            let time_decimal = minutes / 60;
            let hours = Math.floor(time_decimal);
            let minutes_decimal = Math.ceil((time_decimal - hours) * 60);
            return `${hours}h ${minutes_decimal}m`;
        };

        let matchingValidDay = findMatchingValidDay();

        if (matchingValidDay != null) {
            return (
                <Group>
                    <Rect
                        x={width}
                        y={topMarginMain + i * rowHeight}
                        width={200}
                        height={rowHeight}
                        key={2}
                        fill={fill}
                    />
                    <Image
                        width={15}
                        height={15}
                        x={width + 15}
                        y={topMarginMain + i * rowHeight + 10}
                        image={
                            matchingValidDay.valid ? validImage : invalidImage
                        }
                    />
                    <Text
                        x={width + 35}
                        y={topMarginMain + i * rowHeight + 12}
                        fontSize={12}
                        text={minutesToString(matchingValidDay["sleep_sum"])}
                    ></Text>
                </Group>
            );
        }
    };

    return (
        <React.Fragment>
            <div
                style={{
                    color: "#3f51b5",
                    fontWeight: 600,
                    fontSize: "0.75em",
                    marginBottom: "5px",
                }}
            >
                <Title sx={{ display: "inline" }}>24 Stunden Protokoll</Title>
                <IconButtonElement
                    aria-label="edit"
                    size="small"
                    onClick={() => setOrderAsc(!orderAsc)}
                >
                    {!orderAsc ? (
                        <ArrowDropUpIcon
                            style={{ width: ".7em", height: ".7em" }}
                        />
                    ) : (
                        <ArrowDropDownIcon
                            style={{ width: ".7em", height: ".7em" }}
                        />
                    )}
                </IconButtonElement>
            </div>

            {props.rhythmPropositionLogs?.all_logs?.length <= 0 ? null : (
                <TargetRhythm
                    title={t("targetrhythm_proposal.title")}
                    data={props.rhythmPropositionLogs.all_logs}
                    rhythmDate={props.rhythmPropositionLogs.create_time}
                />
            )}

            <TargetRhythm
                data={props.rhythmLogs}
                rhythmDate={props.rhythmDate}
            />
            <div style={{ height: "0.5em" }}></div>

            <div ref={demoRef}>
                <ScrollbarElement style={{ maxHeight: "400px", width: "100%" }}>
                    <Stage
                        width={width + 85}
                        height={rowHeight * allDatesToDisplay.length + 4}
                    >
                        <Layer>
                            {allDatesToDisplay.map((dateToDisplay, i) => {
                                let fill =
                                    i % 2 === 0 ? "white" : "rgb(194,226,247)";
                                return (
                                    <Group>
                                        <Rect
                                            x={
                                                widthPercentageDateDisplay *
                                                width
                                            }
                                            y={topMarginMain + i * rowHeight}
                                            width={
                                                widthPercentageProtocolDisplay *
                                                width
                                            }
                                            height={rowHeight}
                                            key={0}
                                            fill={fill}
                                            stroke="rgb(176,209,221)"
                                        />
                                        <Rect
                                            x={2}
                                            y={topMarginMain + i * rowHeight}
                                            width={
                                                widthPercentageDateDisplay *
                                                width
                                            }
                                            height={rowHeight}
                                            fill={fill}
                                            key={1}
                                            stroke="rgb(176,209,221)"
                                        />
                                        {renderValidDayBox(
                                            dateToDisplay,
                                            i,
                                            fill
                                        )}
                                    </Group>
                                );
                            })}

                            {allDatesDt.map((dateToDisplay, i) => {
                                const fontSize = 16;
                                const fontStyle = isWeekend(dateToDisplay)
                                    ? "bold"
                                    : "normal";
                                const y = calcYFromDate(dateToDisplay);
                                return (
                                    <Text
                                        x={20}
                                        y={y - 5}
                                        fontStyle={fontStyle}
                                        fill="black"
                                        text={`${getWeekday(dateToDisplay)} ${dateToDisplay.toLocaleDateString("de-DE", dateFormat)}`}
                                        fontSize={fontSize}
                                    />
                                );
                            })}

                            {allHoursOfDay.map((hourOfDay, i) => {
                                let strokeWidth =
                                    hourOfDay.getMinutes() === 0 ? 3 : 1;
                                return (
                                    <Line
                                        points={[
                                            calcXFromDate(hourOfDay),
                                            topMarginMain,
                                            calcXFromDate(hourOfDay),
                                            allDatesToDisplay.length *
                                                rowHeight +
                                                topMarginMain,
                                        ]}
                                        stroke={"rgb(176,209,221)"}
                                        strokeWidth={strokeWidth}
                                        lineJoin={"round"}
                                    />
                                );
                            })}

                            {cryLogsSpans.map((cryLogsSpan, i) => {
                                return (
                                    <Line
                                        points={calcCosArrayFromDate(
                                            cryLogsSpan
                                        )}
                                        stroke={"red"}
                                        strokeWidth={2}
                                        lineJoin={"round"}
                                    />
                                );
                            })}

                            {sleepLogsActionPoints.map(
                                (sleepLogActionPoint, i) => {
                                    return (
                                        <Circle
                                            radius={4}
                                            fill={"#3f51b5"}
                                            x={calcXFromDate(
                                                sleepLogActionPoint
                                            )}
                                            y={calcYFromDate(
                                                sleepLogActionPoint
                                            )}
                                        />
                                    );
                                }
                            )}

                            {sleepLogsSpans.map((sleepLogsSpan, i) => {
                                return (
                                    <Line
                                        points={[
                                            calcXFromDate(sleepLogsSpan[0]),
                                            calcYFromDate(sleepLogsSpan[0]),
                                            calcXFromDate(sleepLogsSpan[1]),
                                            calcYFromDate(sleepLogsSpan[1]),
                                        ]}
                                        stroke={"#3f51b5"}
                                        strokeWidth={3}
                                        lineJoin={"round"}
                                    />
                                );
                            })}

                            {mealLogsActionPoints.map(
                                (mealLogsActionPoint, i) => {
                                    return (
                                        <Image
                                            width={20}
                                            height={20}
                                            x={
                                                calcXFromDate(
                                                    mealLogsActionPoint
                                                ) - 10
                                            }
                                            y={
                                                calcYFromDate(
                                                    mealLogsActionPoint
                                                ) - 10
                                            }
                                            image={
                                                images[
                                                    mealLogsActionPointTypes[i]
                                                ]
                                            }
                                        />
                                    );
                                }
                            )}
                        </Layer>
                    </Stage>
                </ScrollbarElement>
            </div>
            <Stage width={width + 15} height={topMargin}>
                <Layer>
                    {allHoursOfDay.map((hourOfDay, i) => {
                        let fontSize = 12;
                        return hourOfDay.getMinutes() === 0 ? (
                            <Text
                                x={calcXFromDate(hourOfDay) - 14}
                                y={5}
                                fontStyle={"normal"}
                                fill="#3f51b5"
                                text={`${zeroPad((hourOfDay.getHours() + 6) % 24, 2)}:00`}
                                fontSize={fontSize}
                            />
                        ) : null;
                    })}
                </Layer>
            </Stage>
            <Stage width={width + 15} height="50">
                <Layer>
                    {images.map((image, i) => {
                        const dist = 70;
                        const top = 10;
                        const leftMargin = 150;
                        return (
                            <Group>
                                <Image
                                    width={20}
                                    height={20}
                                    x={
                                        leftMargin +
                                        widthPercentageDateDisplay * width +
                                        i * dist
                                    }
                                    y={top}
                                    image={image}
                                />
                                <Text
                                    x={
                                        leftMargin +
                                        widthPercentageDateDisplay * width +
                                        i * dist +
                                        5 +
                                        20
                                    }
                                    y={top + 5}
                                    fontStyle={"normal"}
                                    text={mealTypes[i]}
                                ></Text>
                            </Group>
                        );
                    })}

                    <Group>
                        <Line
                            points={getCosCurveForLegend(80)}
                            stroke={"red"}
                            strokeWidth={2}
                            lineJoin={"round"}
                        />
                        <Text
                            x={80 + 20 + 5 + widthPercentageDateDisplay * width}
                            y={10 + 5}
                            fontStyle={"normal"}
                            text={"Cry"}
                        ></Text>
                    </Group>

                    <Group>
                        <Circle
                            radius={4}
                            fill={"#3f51b5"}
                            x={widthPercentageDateDisplay * width}
                            y={20}
                        />
                        <Circle
                            radius={4}
                            fill={"#3f51b5"}
                            x={widthPercentageDateDisplay * width + 20}
                            y={20}
                        />
                        <Line
                            points={[
                                widthPercentageDateDisplay * width,
                                20,
                                widthPercentageDateDisplay * width + 20,
                                20,
                            ]}
                            stroke={"#3f51b5"}
                            strokeWidth={3}
                            lineJoin={"round"}
                        />
                        <Text
                            x={widthPercentageDateDisplay * width + 20 + 5}
                            y={10 + 5}
                            fontStyle={"normal"}
                            text={"Sleep"}
                        ></Text>
                    </Group>
                </Layer>
            </Stage>
        </React.Fragment>
    );
};

export default TwentyFourHoursProtocol;
