import { useTranslation } from "react-i18next";
import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";

const GeneralInformationItem = (props) => {
    const { t } = useTranslation();

    return (
        <span style={{ marginRight: "35px", display: "inline-block" }}>
            <span
                style={{
                    color: "#3f51b5",
                    fontWeight: 600,
                    fontSize: "0.75em",
                }}
            >
                {props.label}
            </span>
            {props.infobox ? (
                <Tooltip title={props.infobox} placement="top">
                    <InfoIcon
                        style={{
                            color: "#3f51b5",
                            display: "inline",
                            fontSize: "18px",
                            marginLeft: "3px",
                            position: "relative",
                            bottom: "-3px",
                        }}
                    />
                </Tooltip>
            ) : null}
            <div>{props.value}</div>
        </span>
    );
};

export default GeneralInformationItem;
