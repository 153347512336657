export const requestService = {
    authRequestConfig,
};

function authRequestConfig(config) {
    if (!config) config = {};
    if (!config.headers) config.headers = {};

    config.headers["Authorization"] =
        `Bearer ${localStorage.getItem("access")}`;

    return config;
}
