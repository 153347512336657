import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

/**
 * This Component provides the Title for the Dashboard.
 * @param props Props
 *
 * @return {JSX.Element}
 */
export default function Title(props) {
    return (
        <Typography
            component="h2"
            variant="h6"
            color="primary"
            gutterBottom
            style={props.sx}
        >
            {props.children}
        </Typography>
    );
}

Title.propTypes = {
    children: PropTypes.node,
};
