import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "../../Elements/Title";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { MenuItem } from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { childrenService } from "../../services/childrenService";
import TextCollapse from "../../Elements/TextCollapse";

/**
 * This Component shows the Algorithm Recommendation.
 *
 * @param props Props
 * @return {JSX.Element}
 */
const ProcessedInterventions = (props) => {
    const { t } = useTranslation();

    const [interventions, setInterventions] = useState([]);
    const [hideProcessButtonId, setHideProcessButtonId] = useState([]);

    useEffect(() => {
        if (props.data) setInterventions(props.data);
        setHideProcessButtonId(props.hideProcessButtonId);
    }, [props]);

    const classifyInvention = async (interventionUrl, newStatus) => {
        await childrenService.reclassifyIntervention(
            interventionUrl,
            newStatus
        );
        props.onChange();
    };

    const dateDisplayProps = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    };

    return (
        <div style={{ padding: "30px", minHeight: "600px", overflow: "auto" }}>
            <Title>{props.title}</Title>
            {interventions && interventions.length > 0 ? (
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableBody>
                            {interventions.map((intervention, index) => {
                                return (
                                    <TableRow key={index} hover={true}>
                                        <TableCell>
                                            <h4
                                                style={{
                                                    marginBottom: "1px",
                                                    marginTop: "5px",
                                                    marginRight: "5px",
                                                    display: "inline-block",
                                                }}
                                            >
                                                {intervention.title}
                                            </h4>
                                            <span>
                                                [
                                                {new Date(
                                                    intervention["status_date"]
                                                ).toLocaleDateString(
                                                    "de-DE",
                                                    dateDisplayProps
                                                )}
                                                ]
                                            </span>
                                            <TextCollapse
                                                text={intervention.text}
                                            >
                                                {" "}
                                            </TextCollapse>
                                        </TableCell>

                                        <TableCell style={{ width: "150px" }}>
                                            {hideProcessButtonId !== 1 ? (
                                                <MenuItem
                                                    onClick={() =>
                                                        classifyInvention(
                                                            intervention["url"],
                                                            1
                                                        )
                                                    }
                                                    style={{
                                                        display: "inline-block",
                                                        padding: "5px",
                                                    }}
                                                >
                                                    <CheckIcon
                                                        style={{
                                                            color: "green",
                                                        }}
                                                    />
                                                </MenuItem>
                                            ) : null}
                                            {hideProcessButtonId !== 2 ? (
                                                <MenuItem
                                                    onClick={() =>
                                                        classifyInvention(
                                                            intervention["url"],
                                                            2
                                                        )
                                                    }
                                                    style={{
                                                        display: "inline-block",
                                                        padding: "5px",
                                                    }}
                                                >
                                                    <RadioButtonUncheckedIcon
                                                        style={{
                                                            color: "orange",
                                                        }}
                                                    />
                                                </MenuItem>
                                            ) : null}
                                            {hideProcessButtonId !== 3 ? (
                                                <MenuItem
                                                    onClick={() =>
                                                        classifyInvention(
                                                            intervention["url"],
                                                            3
                                                        )
                                                    }
                                                    style={{
                                                        display: "inline-block",
                                                        padding: "5px",
                                                    }}
                                                >
                                                    <ClearIcon
                                                        style={{ color: "red" }}
                                                    />
                                                </MenuItem>
                                            ) : null}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <div>{t("algo.noInterventions")}</div>
            )}
        </div>
    );
};

export default ProcessedInterventions;
