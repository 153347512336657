import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: localStorage.getItem("isLoggedIn") === "true",
    accessToken: null,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        login: (state) => {
            localStorage.setItem("isLoggedIn", "true");
            state.isLoggedIn = true;
        },
        logout: (state) => {
            localStorage.setItem("isLoggedIn", "false");
            state.isLoggedIn = false;
        },
    },
});

export const isLoggedIn = (state) => state.user.isLoggedIn;
export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
