/**
 * @example
 * // returns "Hi Peter. I am Anne"
 * formatVarString("Hi {your_name}. I am {my_name}", {your_name: "Peter", my_name: "Anne"});
 *
 * @example
 * // returns http://localhost:8000/de/api/users/11/profile
 * formatVarString("http://localhost:8000/de/api/users/{user_id}/profile", {user_id: 11});
 *
 * @param string
 * @param variableDefinition
 * @returns {string}
 */
import moment from "moment";

export const formatVarString = (string, variableDefinition) => {
    if (!variableDefinition) return string;

    let result = string;
    let regex, value;
    for (let key of Object.keys(variableDefinition)) {
        value = variableDefinition[key];
        regex = new RegExp(`{${key}}`, "g");
        result = result.replace(regex, value);
    }

    return result;
};

/**
 * formats the given Date to a german-style date-string (dd.mm.yyyy)
 *
 * @param {Date} date
 * @returns {string} formattedDate
 */
export const formatDate = (date) => {
    const locales = "de-DE";
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Intl.DateTimeFormat(locales, options).format(date);
};

/**
 * formats the given DateTime to a german-style time-string (hh:mm)
 *
 * @param {Date} date
 * @returns {string} formattedTime
 */
export const formatTime = (date) => {
    const locales = "de-DE";
    const options = { hour: "2-digit", minute: "2-digit" };
    return new Intl.DateTimeFormat(locales, options).format(date);
};

/**
 * @param {string} birthday (yyyy-mm-dd)
 * @returns {number} months
 */
export const calcAgeInMonths = (birthday) => {
    const birthdayMoment = moment(birthday);
    return moment().diff(birthdayMoment, "months");
};

export const isDayTheSame = (day0, day1) => {
    return (
        day0.getDay() === day1.getDay() &&
        day0.getMonth() === day1.getMonth() &&
        day0.getYear() === day1.getYear()
    );
};
