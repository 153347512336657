import Box from "@mui/material/Box";
import { Modal } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
    height: 600,
    borderRadius: "4px",
    overflow: "auto",
};

const ModalElement = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        handleOpen(openModal) {
            setOpen(openModal);
        },
    }));

    return (
        <div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div
                        style={{
                            backgroundColor: props.background,
                            borderRadius: "4px",
                        }}
                    >
                        {props.children}
                    </div>
                </Box>
            </Modal>
        </div>
    );
});

export default ModalElement;
