import TextField from "@mui/material/TextField";
import * as React from "react";
import { useState } from "react";
import { authenticationService } from "../../services/AuthenticationService";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { userService } from "../../services/userService";
import { useNavigate } from "react-router-dom";
import { login } from "../../reducers/user";
import { useDispatch } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";

/**
 * This Component provides the Login logic. There is no registration logic implement yet.
 *
 * @return {JSX.Element}
 */
const Login = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [currentCredentials, setCurrentCredentials] = useState({
        username: "",
        password: "",
    });

    const [failAlertOpen, setFailAlertOpen] = React.useState(false);
    const [failAlertMessage, setFailAlertMessage] = React.useState("");

    let navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        const credentials = currentCredentials;

        if (!credentials.username || !credentials.password) {
            setFailAlertMessage(t("login.wrong_password_message"));
            setFailAlertOpen(true);
        } else {
            authenticationService
                .login(credentials.username, credentials.password)
                .then((_) => {
                    userService.getUser().then((response) => {
                        const childrenUrl = response.children;
                        localStorage.setItem("childrenUrl", childrenUrl);
                        dispatch(login());
                        navigate("/home");
                    });
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        setFailAlertMessage(t("login.wrong_password_message"));
                        setFailAlertOpen(true);
                    } else {
                        setFailAlertMessage(t("login.unknown_error_message"));
                        setFailAlertOpen(true);
                    }
                });
        }
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
            }}
        >
            <form onSubmit={handleClick}>
                <h1>{t("login.title")}</h1>
                <TextField
                    style={{ marginBottom: "10px" }}
                    fullWidth={true}
                    variant="standard"
                    label={t("login.username")}
                    onChange={(event) =>
                        setCurrentCredentials({
                            username: event.target.value,
                            password: currentCredentials.password,
                        })
                    }
                />
                <TextField
                    fullWidth={true}
                    type="password"
                    variant="standard"
                    label={t("login.password")}
                    onChange={(event) =>
                        setCurrentCredentials({
                            username: currentCredentials.username,
                            password: event.target.value,
                        })
                    }
                />
                <br />
                <br />
                <a
                    href=""
                    style={{ color: "#3f51b5" }}
                    onClick={() => navigate("/RequestPasswordReset")}
                >
                    {t("reset_pw.password_forgot_question")}{" "}
                </a>
                <Box pt={4}>
                    <Button variant="contained" color="primary" type="submit">
                        {t("login")}
                    </Button>
                    <Collapse in={failAlertOpen}>
                        <div style={{ marginTop: "30px" }}>
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        size="small"
                                        onClick={() => setFailAlertOpen(false)}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                <span>{failAlertMessage}</span>
                            </Alert>
                        </div>
                    </Collapse>
                </Box>
            </form>
        </div>
    );
};

export default Login;
