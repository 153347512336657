import { requestService } from "./requestService";
import axios from "axios";

export const userService = {
    getUser,
    getUserWellness,
};

async function getUser() {
    const url = localStorage.getItem("entrypoint");
    const config = requestService.authRequestConfig();
    const response = await axios.get(url, config);
    return response.data;
}

async function getUserWellness(userWellnessURL) {
    try {
        const response = await axios.get(userWellnessURL);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}
