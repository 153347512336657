import axios from "axios";
import { localStorageService } from "../../services/LocalStorageService";
import { authenticationService } from "../../services/AuthenticationService";

// information src: https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da

console.log("axios interceptors activated");

// Interceptors
axios.interceptors.request.use(
    (request) => {
        if (!request) return null;
        if (
            request.url.includes("public") ||
            request.url.includes("password_reset")
        )
            return request;

        const accessToken = localStorageService.getAccessToken();
        if (!accessToken) {
            window.location.href = "/login";
            return;
        }

        request.headers["Authorization"] =
            `Bearer ${localStorage.getItem("access")}`;
        return request;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (
            error.response?.status === 401 &&
            !originalRequest.url.includes("public")
        ) {
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    await authenticationService.refreshToken();
                    originalRequest.headers["Authorization"] =
                        `Bearer ${localStorageService.getAccessToken()}`;
                    return axios(originalRequest);
                } catch (_) {
                    window.location.href = "/login";
                }
            } else {
                window.location.href = "/login";
            }
        } else {
            return error.response;
        }
    }
);
