import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const PaperElement = styled(Paper)(({ theme }) => ({
    marginBottom: "20px",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.08)",
    border: "1px solid #F0F0F0",
    padding: theme.spacing(2.8),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    background: "#FFFFFF",
}));

export default PaperElement;
