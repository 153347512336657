import React, { useEffect, useReducer, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { childrenService } from "../../services/childrenService";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import { calcAgeInMonths, formatVarString } from "../Utils/Util";
import { useNavigate } from "react-router-dom";
import PaperElement from "../../Elements/PaperElement";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableHead: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    hideModeButton: {
        textAlign: "right",
    },
    hiddenRow: {
        backgroundColor: "rgba(224, 224, 224, 1)",
    },
});

const hiddenChildReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_DATA":
            const hiddenChildrenJson = localStorage.getItem("hiddenChildren");
            return hiddenChildrenJson ? JSON.parse(hiddenChildrenJson) : [];

        case "ADD":
            if (!state.includes(action.value)) {
                return [...state, action.value];
            }
            return state;

        case "REMOVE":
            return state.filter((id) => id !== action.value);
    }
};

/**
 * Toggles asc and desc if the same sorting alg is chosen
 * @param state
 * @param action
 * @returns {string}
 */
const childrenSortReducer = (state, action) => {
    const isAsc = state.substring(state.lastIndexOf("_") + 1) === "ASC";
    if (state.startsWith(action)) return `${action}_${isAsc ? "DESC" : "ASC"}`;
    return `${action}_ASC`;
};

/**
 * This Component provides the Overview for all Children.
 *
 * @return {JSX.Element}
 */
const ChildrenOverview = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [hiddenChildren, dispatchHiddenChildren] = useReducer(
        hiddenChildReducer,
        null
    );
    const [childrenSortBy, dispatchChildrenSortBy] = useReducer(
        childrenSortReducer,
        "ID_DESC"
    );
    const [hideMode, setHideMode] = useState(false);

    let navigate = useNavigate();

    const setRedirect = (url) => {
        navigate(url);
    };

    const [children, setChildren] = useState([]);

    const fetchData = async () => {
        const children = await childrenService.getChildren();
        console.log(children);
        setChildren(children);
    };

    useEffect(() => {
        dispatchHiddenChildren({ type: "FETCH_DATA" });
        fetchData();
    }, []);

    useEffect(() => {
        if (hiddenChildren !== null) {
            localStorage.setItem(
                "hiddenChildren",
                JSON.stringify(hiddenChildren)
            );
        }
    }, [hiddenChildren]);

    const hideChild = (id) =>
        dispatchHiddenChildren({ type: "ADD", value: id });
    const showChild = (id) =>
        dispatchHiddenChildren({ type: "REMOVE", value: id });
    const toggleVisibility = (id) => {
        if (hiddenChildren.includes(id)) showChild(id);
        else hideChild(id);
    };

    /**
     * @returns {{birthday: *, parent: *, childName: *, lastActivity: *, id: *}[]}
     */
    const getRows = () => {
        return children.sort(sortChildren).map((child) => {
            let careTakers = "";

            child.caretakers.forEach((careTaker, idx) => {
                if (idx !== 0) careTakers += ", ";
                careTakers += `${careTaker.first_name} ${careTaker.last_name}`;
            });

            const today = moment();
            const lastActive = moment(child.last_change);

            const dayDiff = today.diff(lastActive, "days");
            const hourDiff = today.diff(lastActive, "hours");
            let lastActivity;

            const ageInMonths = calcAgeInMonths(child.birthday);
            const years = Math.floor(ageInMonths / 12);
            const months = ageInMonths - 12 * years;
            const age =
                years !== 0
                    ? `${years} ${t("infos.age.years")}, ${months} ${t("infos.age.months")}`
                    : `${months} ${t("infos.age.months")}`;

            const birthday = new Date(child.birthday).toLocaleDateString(
                "de-DE",
                dateDisplayProps
            );

            if (dayDiff !== 0) {
                lastActivity = formatVarString(
                    t("home.list.lastActivity.days"),
                    { days: dayDiff }
                );
            } else if (hourDiff !== 0) {
                lastActivity = formatVarString(
                    t("home.list.lastActivity.hours"),
                    { hours: hourDiff }
                );
            } else {
                lastActivity = t("home.list.lastActivity.now");
            }
            return {
                id: child.id,
                childName: child.name,
                birthday: birthday,
                age: age,
                parent: careTakers,
                lastActivity: lastActivity,
                todo: child.todo,
            };
        });
    };

    function sortChildren(childA, childB) {
        switch (childrenSortBy) {
            case "ID_ASC":
                return sortById(childA, childB);
            case "ID_DESC":
                return sortById(childB, childA);
            case "CHILD_NAME_ASC":
                return sortByChildName(childA, childB);
            case "CHILD_NAME_DESC":
                return sortByChildName(childB, childA);
            case "BIRTHDAY_ASC":
                return sortByBirthday(childA, childB);
            case "BIRTHDAY_DESC":
                return sortByBirthday(childB, childA);
            case "LAST_ACTIVITY_ASC":
                return sortByLastActivity(childA, childB);
            case "LAST_ACTIVITY_DESC":
                return sortByLastActivity(childB, childA);
            default:
                return sortByChildName(childB, childA);
        }
    }

    function sortByChildName(childA, childB) {
        if (childA.name > childB.name) return -1;
        if (childA.name < childB.name) return 1;
        return 0;
    }

    function sortByBirthday(childA, childB) {
        return (
            new Date(childA.birthday).getTime() -
            new Date(childB.birthday).getTime()
        );
    }

    function sortById(childA, childB) {
        return childB.id - childA.id;
    }

    function sortByLastActivity(childA, childB) {
        return (
            new Date(childB.last_change).getTime() -
            new Date(childA.last_change).getTime()
        );
    }

    const displaySortIcon = (criterion) => {
        if (!childrenSortBy.startsWith(criterion)) return null;
        if (childrenSortBy.endsWith("ASC")) return <ArrowDropUpIcon />;
        return <ArrowDropDownIcon />;
    };

    const dateDisplayProps = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    };

    return (
        <div>
            <h1>{t("home.title")}</h1>
            <div className={classes.hideModeButton}>
                {!hideMode ? (
                    <Button onClick={() => setHideMode(true)}>
                        {t("home.visibility.show")}
                    </Button>
                ) : (
                    <Button onClick={() => setHideMode(false)}>
                        {t("home.visibility.hide")}
                    </Button>
                )}
            </div>
            <TableContainer component={PaperElement}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                onClick={() => dispatchChildrenSortBy("ID")}
                                className={classes.tableHead}
                            >
                                {t("home.list.id")} {displaySortIcon("ID")}
                            </TableCell>
                            <TableCell
                                onClick={() =>
                                    dispatchChildrenSortBy("CHILD_NAME")
                                }
                                className={classes.tableHead}
                            >
                                {t("home.list.child")}{" "}
                                {displaySortIcon("CHILD_NAME")}
                            </TableCell>
                            <TableCell
                                onClick={() =>
                                    dispatchChildrenSortBy("BIRTHDAY")
                                }
                                align="right"
                                className={classes.tableHead}
                            >
                                {t("home.list.birthday")}{" "}
                                {displaySortIcon("BIRTHDAY")}
                            </TableCell>
                            <TableCell
                                onClick={() =>
                                    dispatchChildrenSortBy("BIRTHDAY")
                                }
                                align="right"
                                className={classes.tableHead}
                            >
                                {t("home.list.age")}{" "}
                                {displaySortIcon("BIRTHDAY")}
                            </TableCell>
                            <TableCell
                                align="right"
                                className={classes.tableHead}
                            >
                                {t("home.list.parent")}{" "}
                                {displaySortIcon("PARENT_NAME")}
                            </TableCell>
                            <TableCell
                                onClick={() =>
                                    dispatchChildrenSortBy("LAST_ACTIVITY")
                                }
                                align="right"
                                className={classes.tableHead}
                            >
                                {t("home.list.lastActivity")}{" "}
                                {displaySortIcon("LAST_ACTIVITY")}
                            </TableCell>
                            {hideMode && (
                                <TableCell align="right">
                                    {t("home.list.visibility")}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getRows().map((row) => {
                            if (
                                hiddenChildren &&
                                hiddenChildren.includes(row.id) &&
                                !hideMode
                            )
                                return null;
                            return (
                                <TableRow
                                    className={
                                        hiddenChildren &&
                                        hiddenChildren.includes(row.id)
                                            ? classes.hiddenRow
                                            : ""
                                    }
                                    key={row.id}
                                    hover={true}
                                    onClick={(e) => {
                                        setRedirect("/dashboard/" + row.id);
                                    }}
                                >
                                    <TableCell>#{row.id}</TableCell>
                                    <TableCell>{row.childName}</TableCell>
                                    <TableCell align="right">
                                        {row.birthday}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.age}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.parent}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.todo ? (
                                            <Tooltip
                                                title="Änderung beim Kind vorhanden! TBD"
                                                placement="top"
                                            >
                                                <NotificationsIcon
                                                    style={{
                                                        color: "#3f51b5",
                                                        position: "relative",
                                                        marginRight: "5px",
                                                        bottom: "-8px",
                                                    }}
                                                />
                                            </Tooltip>
                                        ) : null}
                                        {row.lastActivity}
                                    </TableCell>
                                    {hideMode && (
                                        <TableCell
                                            align="right"
                                            className="toggleVisibilityBtn"
                                        >
                                            <Checkbox
                                                checked={
                                                    !hiddenChildren.includes(
                                                        row.id
                                                    )
                                                }
                                                className="toggleVisibilityBtn"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleVisibility(row.id);
                                                }}
                                                color="primary"
                                                icon={<VisibilityOffIcon />}
                                                checkedIcon={<VisibilityIcon />}
                                                style={{ height: "16px" }}
                                            />
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ChildrenOverview;
